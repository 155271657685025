<template>
  <div
    class="
      page-template-default page page-id-33
      fl-builder fl-framework-base fl-preset-default fl-full-width
    "
    itemscope="itemscope"
    itemtype="https://schema.org/WebPage"
  >
    <div class="fl-page">
      <v-header></v-header>
      <div class="fl-page-content" itemprop="mainContentOfPage">
        <div class="fl-content-full container">
          <div class="row">
            <div class="fl-content col-md-12">
              <article
                class="fl-post post-97 page type-page status-publish hentry"
                id="fl-post-97"
                itemscope="itemscope"
                itemtype="https://schema.org/CreativeWork"
              >
                <div class="fl-post-content clearfix" itemprop="text">
                  <div
                    class="
                      fl-builder-content
                      fl-builder-content-97
                      fl-builder-content-primary
                      fl-builder-global-templates-locked
                    "
                    data-post-id="97"
                  >
                    <div
                      class="
                        fl-row
                        fl-row-full-width
                        fl-row-bg-photo
                        fl-node-5df74e654efea
                        fl-visible-desktop-medium
                      "
                      data-node="5df74e654efea"
                    >
                      <div class="fl-row-content-wrap">
                        <div class="fl-row-content fl-row-full-width fl-node-content">
                          <div class="fl-col-group fl-node-5df74e655043e" data-node="5df74e655043e">
                            <div class="fl-col fl-node-5df74e6550508" data-node="5df74e6550508">
                              <div class="fl-col-content fl-node-content">
                                <div
                                  class="fl-module fl-module-rich-text fl-node-5df74e8280063"
                                  data-node="5df74e8280063"
                                >
                                  <div class="fl-module-content fl-node-content">
                                    <div class="fl-rich-text">
                                      <p>&nbsp;</p>
                                      <p>&nbsp;</p>
                                      <p>&nbsp;</p>
                                      <p>&nbsp;</p>
                                      <p>&nbsp;</p>
                                      <p>&nbsp;</p>
                                      <p>&nbsp;</p>
                                      <p>&nbsp;</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="fl-row fl-row-fixed-width fl-row-bg-none fl-node-5da35b06ea5d0"
                      data-node="5da35b06ea5d0"
                    >
                      <div class="fl-row-content-wrap">
                        <div class="fl-row-content fl-row-fixed-width fl-node-content">
                          <div class="fl-col-group fl-node-5dc81ca60c2bf" data-node="5dc81ca60c2bf">
                            <div class="fl-col fl-node-5dc81ca60c3b0" data-node="5dc81ca60c3b0">
                              <div class="fl-col-content fl-node-content">
                                <div
                                  class="fl-module fl-module-rich-text fl-node-5dc81ca60c21e"
                                  data-node="5dc81ca60c21e"
                                >
                                  <div class="fl-module-content fl-node-content">
                                    <div class="fl-rich-text">
                                      <h1>{{ $t('pages.mileage.title') }}</h1>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="fl-col-group fl-node-5da35b06f07aa" data-node="5da35b06f07aa">
                            <div class="fl-col fl-node-5da35b06f0917" data-node="5da35b06f0917">
                              <div class="fl-col-content fl-node-content">
                                <div
                                  class="fl-module fl-module-photo fl-node-5dad7b610c087 fl-fade-in"
                                  data-node="5dad7b610c087"
                                  data-animation-delay="0.0"
                                >
                                  <div class="fl-module-content fl-node-content">
                                    <div class="fl-photo fl-photo-align-center" itemscope>
                                      <div class="fl-photo-content fl-photo-img-jpg">
                                        <img
                                          class="fl-photo-img wp-image-342 size-full"
                                          src="../assets/sanjiang/mile.jpg"
                                          alt="mile2"
                                          itemprop="image"
                                          height="7285"
                                          width="4031"
                                          title="mile2"
                                          sizes="(max-width: 4031px) 100vw, 4031px"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>

      <v-footer></v-footer>
    </div>
  </div>
</template>

<script>
import vFooter from '../components/footer';
import vHeader from '../components/header';
export default {
  name: 'first',
  components: {
    vFooter,
    vHeader,
  },

  data() {
    return {};
  },

  methods: {
    jumpPage() {
      this.$router.push('helloworld');
    },
  },
};
</script>

<style scoped></style>
