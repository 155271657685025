<template>
  <div
    class="
      page-template-default page page-id-33
      fl-builder fl-framework-base fl-preset-default fl-full-width
    "
    itemscope="itemscope"
    itemtype="https://schema.org/WebPage"
  >
    <div class="fl-page">
      <v-header></v-header>
      <div class="fl-page-content" itemprop="mainContentOfPage">
        <div class="fl-content-full container">
          <div class="row">
            <div class="fl-content col-md-12">
              <article
                class="fl-post post-5 page type-page status-publish hentry"
                id="fl-post-5"
                itemscope="itemscope"
              >
                <div class="fl-post-content clearfix" itemprop="text">
                  <div
                    class="
                      fl-builder-content
                      fl-builder-content-5
                      fl-builder-content-primary
                      fl-builder-global-templates-locked
                    "
                    data-post-id="5"
                  >
                    <div
                      class="
                        fl-row
                        fl-row-full-width
                        fl-row-bg-photo
                        fl-node-5df1dabc788ce
                        fl-visible-desktop-medium
                      "
                      data-node="5df1dabc788ce"
                    >
                      <div class="fl-row-content-wrap">
                        <div class="fl-row-content fl-row-full-width fl-node-content">
                          <div class="fl-col-group fl-node-5df1dabc7a895" data-node="5df1dabc7a895">
                            <div class="fl-col fl-node-5df1dabc7a96c" data-node="5df1dabc7a96c">
                              <div class="fl-col-content fl-node-content">
                                <div
                                  class="fl-module fl-module-rich-text fl-node-5df1db3c6ee98"
                                  data-node="5df1db3c6ee98"
                                >
                                  <div class="fl-module-content fl-node-content">
                                    <div class="fl-rich-text">
                                      <p>&nbsp;</p>
                                      <p>&nbsp;</p>
                                      <p>&nbsp;</p>
                                      <p>&nbsp;</p>
                                      <p>&nbsp;</p>
                                      <p>&nbsp;</p>
                                      <p>&nbsp;</p>
                                      <p>&nbsp;</p>
                                      <p>&nbsp;</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="fl-row fl-row-fixed-width fl-row-bg-none fl-node-5da35b06ea5d0"
                      data-node="5da35b06ea5d0"
                    >
                      <div class="fl-row-content-wrap">
                        <div class="fl-row-content fl-row-fixed-width fl-node-content">
                          <div class="fl-col-group fl-node-5dc81afe4f857" data-node="5dc81afe4f857">
                            <div class="fl-col fl-node-5dc81afe4f933" data-node="5dc81afe4f933">
                              <div class="fl-col-content fl-node-content">
                                <div
                                  class="fl-module fl-module-rich-text fl-node-5dc81afe4f78b"
                                  data-node="5dc81afe4f78b"
                                >
                                  <div class="fl-module-content fl-node-content">
                                    <div class="fl-rich-text">
                                      <h1>{{ $t('pages.about.title') }}</h1>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="
                              fl-col-group
                              fl-node-5e12af89c3f4d
                              fl-col-group-equal-height
                              fl-col-group-align-center
                            "
                            data-node="5e12af89c3f4d"
                          >
                            <div
                              class="fl-col fl-node-5e12af89c4091 fl-col-small"
                              data-node="5e12af89c4091"
                            >
                              <div class="fl-col-content fl-node-content">
                                <div
                                  class="fl-module fl-module-photo fl-node-5da35b186fe80 fl-slide-down"
                                  data-node="5da35b186fe80"
                                  data-animation-delay="0.0"
                                >
                                  <div class="fl-module-content fl-node-content">
                                    <div
                                      class="fl-photo fl-photo-align-center"
                                      itemscope
                                      itemtype="https://schema.org/ImageObject"
                                    >
                                      <div class="fl-photo-content fl-photo-img-jpg">
                                        <img
                                          class="fl-photo-img wp-image-275 size-full"
                                          src="https://irlinkdemo.com/sanjiang/wp-content/uploads/2019/10/sanjiang-5.jpg"
                                          alt="sanjiang (5)"
                                          itemprop="image"
                                          height="1080"
                                          width="1620"
                                          title="sanjiang (5)"
                                          srcset="
                                            https://irlinkdemo.com/sanjiang/wp-content/uploads/2019/10/sanjiang-5.jpg          1620w,
                                            https://irlinkdemo.com/sanjiang/wp-content/uploads/2019/10/sanjiang-5-300x200.jpg   300w,
                                            https://irlinkdemo.com/sanjiang/wp-content/uploads/2019/10/sanjiang-5-768x512.jpg   768w,
                                            https://irlinkdemo.com/sanjiang/wp-content/uploads/2019/10/sanjiang-5-1024x683.jpg 1024w
                                          "
                                          sizes="(max-width: 1620px) 100vw, 1620px"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              class="fl-col fl-node-5e12af89c4111 fl-col-small"
                              data-node="5e12af89c4111"
                            >
                              <div class="fl-col-content fl-node-content">
                                <div
                                  class="
                                    fl-module fl-module-rich-text fl-node-5da35b0badb02 fl-slide-down
                                  "
                                  data-node="5da35b0badb02"
                                  data-animation-delay="0.0"
                                >
                                  <div class="fl-module-content fl-node-content">
                                    <div class="fl-rich-text" align="justify">
                                      <p>
                                        <strong>{{ $t('pages.about.companyInfo.item1') }} </strong
                                        >{{ $t('pages.about.companyInfo.item2') }}
                                        <strong>{{ $t('pages.about.companyInfo.item3') }} </strong
                                        >{{ $t('pages.about.companyInfo.item4') }}
                                        <strong>{{ $t('pages.about.companyInfo.item5') }} </strong
                                        ><strong>{{ $t('pages.about.companyInfo.item6') }} </strong
                                        ><strong>{{ $t('pages.about.companyInfo.item7') }} </strong
                                        ><strong>{{ $t('pages.about.companyInfo.item8') }} </strong
                                        ><strong>{{ $t('pages.about.companyInfo.item9') }} </strong
                                        >{{ $t('pages.about.companyInfo.item10') }}
                                        <strong>{{ $t('pages.about.companyInfo.item11') }} </strong
                                        ><strong>{{ $t('pages.about.companyInfo.item12') }} </strong
                                        ><strong>{{ $t('pages.about.companyInfo.item13') }} </strong
                                        ><strong>{{ $t('pages.about.companyInfo.item14') }} </strong
                                        ><strong>{{ $t('pages.about.companyInfo.item15') }} </strong
                                        ><strong>{{ $t('pages.about.companyInfo.item16') }} </strong
                                        ><strong>{{ $t('pages.about.companyInfo.item17') }} </strong
                                        ><strong>{{ $t('pages.about.companyInfo.item18') }} </strong
                                        ><strong>{{ $t('pages.about.companyInfo.item19') }} </strong
                                        ><strong>{{ $t('pages.about.companyInfo.item20') }} </strong
                                        ><strong>{{ $t('pages.about.companyInfo.item21') }} </strong
                                        ><strong>{{ $t('pages.about.companyInfo.item22') }} </strong
                                        >{{ $t('pages.about.companyInfo.item23') }}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="
                              fl-col-group
                              fl-node-5dc81ad0ee73c
                              fl-col-group-equal-height
                              fl-col-group-align-center
                            "
                            data-node="5dc81ad0ee73c"
                          >
                            <div class="fl-col fl-node-5dc81ad0ee856" data-node="5dc81ad0ee856">
                              <div class="fl-col-content fl-node-content"></div>
                            </div>
                            <div
                              class="fl-col fl-node-5dc81ad0ee896 fl-col-small"
                              data-node="5dc81ad0ee896"
                            >
                              <div class="fl-col-content fl-node-content"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>

      <v-footer></v-footer>
    </div>
  </div>
</template>

<script>
import vFooter from '../components/footer';
import vHeader from '../components/header';
export default {
  name: 'first',
  components: {
    vFooter,
    vHeader,
  },

  data() {
    return {};
  },

  methods: {},
};
</script>

<style scoped></style>
