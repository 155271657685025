import util from '@/util';
import config from '@/config';

export default {
  get(locale, year, pageNum, pageSize) {
    return util.fetch.get(`${config.apiRoot}/announcement`, {
      params: { locale, year, pageNum, pageSize },
    });
  },
};
