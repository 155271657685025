import Vue from 'vue';
import VueRouter from 'vue-router';
import first from '@/pages/first';
import industry from '@/pages/industry';
import about from '@/pages/about';
import framework from '@/pages/framework';
import mileage from '@/pages/mileage';
import product from '@/pages/product';
import money from '@/pages/money';
import notice from '@/pages/notice';
import achieve from '@/pages/achieve';
import active from '@/pages/active';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: first,
  },
  {
    path: '/about', // 概况
    component: about,
  },
  {
    path: '/framework', // 架构
    component: framework,
  },
  {
    path: '/mileage', // 里程碑
    component: mileage,
  },
  {
    path: '/industry', // 产业布局
    component: industry,
  },
  {
    path: '/product', // 产品布局
    component: product,
  },
  {
    path: '/money', // 财务
    component: money,
  },
  {
    path: '/achieve', // 业绩
    component: achieve,
  },
  {
    path: '/notice', // 公告
    component: notice,
  },
  {
    path: '/active', // 动态
    component: active,
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
