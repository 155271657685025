<template>
  <div
    class="
      page-template-default page page-id-33
      fl-builder fl-framework-base fl-preset-default fl-full-width
    "
    itemscope="itemscope"
    itemtype="https://schema.org/WebPage"
  >
    <div class="fl-page">
      <v-header></v-header>
      <div class="fl-page-content" itemprop="mainContentOfPage">
        <div class="fl-content-full container">
          <div class="row">
            <div class="fl-content col-md-12">
              <article
                class="fl-post post-111 page type-page status-publish hentry"
                id="fl-post-111"
                itemscope="itemscope"
                itemtype="https://schema.org/CreativeWork"
              >
                <div class="fl-post-content clearfix" itemprop="text">
                  <div
                    class="
                      fl-builder-content
                      fl-builder-content-111
                      fl-builder-content-primary
                      fl-builder-global-templates-locked
                    "
                    data-post-id="111"
                  >
                    <div
                      class="
                        fl-row
                        fl-row-full-width
                        fl-row-bg-photo
                        fl-node-5da354ca49f92
                        fl-visible-desktop-medium
                      "
                      data-node="5da354ca49f92"
                    >
                      <div class="fl-row-content-wrap">
                        <div class="fl-row-content fl-row-fixed-width fl-node-content">
                          <div class="fl-col-group fl-node-5da354ca4d850" data-node="5da354ca4d850">
                            <div
                              class="fl-col fl-node-5da354ca4da2d fl-col-has-cols"
                              data-node="5da354ca4da2d"
                            >
                              <div class="fl-col-content fl-node-content">
                                <div
                                  class="fl-col-group fl-node-5da362cd10213 fl-col-group-nested"
                                  data-node="5da362cd10213"
                                >
                                  <div class="fl-col fl-node-5da362cd103f3" data-node="5da362cd103f3">
                                    <div class="fl-col-content fl-node-content">
                                      <div
                                        class="fl-module fl-module-rich-text fl-node-5da355407229c"
                                        data-node="5da355407229c"
                                      >
                                        <div class="fl-module-content fl-node-content">
                                          <div class="fl-rich-text">
                                            <p>&nbsp;</p>
                                            <p>&nbsp;</p>
                                            <p>&nbsp;</p>
                                            <p>&nbsp;</p>
                                            <p>&nbsp;</p>
                                            <p>&nbsp;</p>
                                            <p>&nbsp;</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="
                        fl-row
                        fl-row-fixed-width
                        fl-row-bg-none
                        fl-node-5da35b06ea5d0
                        fl-visible-desktop-medium
                      "
                      data-node="5da35b06ea5d0"
                    >
                      <div class="fl-row-content-wrap">
                        <div class="fl-row-content fl-row-fixed-width fl-node-content">
                          <div class="fl-col-group fl-node-5dca5f8f63b8f" data-node="5dca5f8f63b8f">
                            <div class="fl-col fl-node-5dca5f8f63c54" data-node="5dca5f8f63c54">
                              <div class="fl-col-content fl-node-content">
                                <div
                                  class="fl-module fl-module-rich-text fl-node-5dca5f8f63afa"
                                  data-node="5dca5f8f63afa"
                                >
                                  <div class="fl-module-content fl-node-content">
                                    <div class="fl-rich-text">
                                      <h1>
                                        {{ $t('pages.product.title') }}
                                      </h1>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="fl-col-group fl-node-5da363bccec95" data-node="5da363bccec95">
                            <div class="fl-col fl-node-5da363bccee1c" data-node="5da363bccee1c">
                              <div class="fl-col-content fl-node-content">
                                <div
                                  class="fl-module fl-module-photo fl-node-5daff73dd649a fl-fade-in"
                                  data-node="5daff73dd649a"
                                  data-animation-delay="0.0"
                                >
                                  <div class="fl-module-content fl-node-content">
                                    <div class="fl-photo fl-photo-align-center">
                                      <div class="fl-photo-content fl-photo-img-jpg">
                                        <img
                                          class="fl-photo-img wp-image-332 size-full"
                                          src="../assets/sanjiang/product-mmm.jpg"
                                          alt="product-mmm"
                                          itemprop="image"
                                          height="1838"
                                          width="2567"
                                          title="product-mmm"
                                          sizes="(max-width: 2567px) 100vw, 2567px"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="
                        fl-row fl-row-fixed-width fl-row-bg-none fl-node-5daff8b76b7a0 fl-visible-mobile
                      "
                      data-node="5daff8b76b7a0"
                    >
                      <div class="fl-row-content-wrap">
                        <div class="fl-row-content fl-row-fixed-width fl-node-content">
                          <div class="fl-col-group fl-node-5daff8b76f882" data-node="5daff8b76f882">
                            <div class="fl-col fl-node-5daff8b76fa98" data-node="5daff8b76fa98">
                              <div class="fl-col-content fl-node-content">
                                <div
                                  class="fl-module fl-module-photo fl-node-5daff8bece1c2 fl-fade-in"
                                  data-node="5daff8bece1c2"
                                  data-animation-delay="0.0"
                                >
                                  <div class="fl-module-content fl-node-content">
                                    <div class="fl-photo fl-photo-align-center">
                                      <div class="fl-photo-content fl-photo-img-jpg">
                                        <img
                                          class="fl-photo-img wp-image-335 size-full"
                                          src="../assets/sanjiang/product-mmm2.jpg"
                                          alt="product-mmm3"
                                          itemprop="image"
                                          height="5607"
                                          width="792"
                                          title="product-mmm3"
                                          sizes="(max-width: 792px) 100vw, 792px"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>

      <v-footer></v-footer>
    </div>
  </div>
</template>

<script>
import vFooter from '../components/footer';
import vHeader from '../components/header';
export default {
  name: 'first',
  components: {
    vFooter,
    vHeader,
  },

  data() {
    return {};
  },

  methods: {
    jumpPage() {
      this.$router.push('helloworld');
    },
  },
};
</script>

<style scoped></style>
