<template>
  <div
    class="
      page-template-default page page-id-33
      fl-builder fl-framework-base fl-preset-default fl-full-width
    "
    itemscope="itemscope"
    itemtype="https://schema.org/WebPage"
  >
    <div class="fl-page">
      <v-header></v-header>
      <div class="fl-page-content" itemprop="mainContentOfPage">
        <div class="fl-content-full container">
          <div class="row">
            <div class="fl-content col-md-12">
              <article
                class="fl-post post-118 page type-page status-publish hentry"
                id="fl-post-118"
                itemscope="itemscope"
                itemtype="https://schema.org/CreativeWork"
              >
                <div class="fl-post-content clearfix" itemprop="text">
                  <div
                    class="
                      fl-builder-content
                      fl-builder-content-118
                      fl-builder-content-primary
                      fl-builder-global-templates-locked
                    "
                    data-post-id="118"
                  >
                    <div
                      class="
                        fl-row
                        fl-row-full-width
                        fl-row-bg-photo
                        fl-node-5da354ca49f92
                        fl-visible-desktop-medium
                      "
                      data-node="5da354ca49f92"
                    >
                      <div class="fl-row-content-wrap">
                        <div class="fl-row-content fl-row-fixed-width fl-node-content">
                          <div class="fl-col-group fl-node-5da354ca4d850" data-node="5da354ca4d850">
                            <div
                              class="fl-col fl-node-5da354ca4da2d fl-col-has-cols"
                              data-node="5da354ca4da2d"
                            >
                              <div class="fl-col-content fl-node-content">
                                <div
                                  class="fl-col-group fl-node-5da361f7ea075 fl-col-group-nested"
                                  data-node="5da361f7ea075"
                                >
                                  <div class="fl-col fl-node-5da361f7ea28b" data-node="5da361f7ea28b">
                                    <div class="fl-col-content fl-node-content">
                                      <div
                                        class="fl-module fl-module-rich-text fl-node-5da355407229c"
                                        data-node="5da355407229c"
                                      >
                                        <div class="fl-module-content fl-node-content">
                                          <div class="fl-rich-text">
                                            <p>&nbsp;</p>
                                            <p>&nbsp;</p>
                                            <p>&nbsp;</p>
                                            <p>&nbsp;</p>
                                            <p>&nbsp;</p>
                                            <p>&nbsp;</p>
                                            <p>&nbsp;</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="fl-row fl-row-fixed-width fl-row-bg-none fl-node-5da819e8c00a2"
                      data-node="5da819e8c00a2"
                    >
                      <div class="fl-row-content-wrap">
                        <div class="fl-row-content fl-row-fixed-width fl-node-content">
                          <div class="fl-col-group fl-node-5dca6052877de" data-node="5dca6052877de">
                            <div class="fl-col fl-node-5dca6052878d3" data-node="5dca6052878d3">
                              <div class="fl-col-content fl-node-content">
                                <div
                                  class="fl-module fl-module-rich-text fl-node-5dca605287751"
                                  data-node="5dca605287751"
                                >
                                  <div class="fl-module-content fl-node-content">
                                    <div class="fl-rich-text">
                                      <h1>
                                        {{ $t('pages.achieve.title') }}
                                      </h1>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="fl-col-group fl-node-5dca70cdbf0a7" data-node="5dca70cdbf0a7">
                            <div class="fl-col fl-node-5dca70cdbf176" data-node="5dca70cdbf176">
                              <div class="fl-col-content fl-node-content">
                                <div
                                  class="fl-module fl-module-rich-text fl-node-5dca70cdbf010"
                                  data-node="5dca70cdbf010"
                                >
                                  <div class="fl-module-content fl-node-content">
                                    <div class="fl-rich-text">
                                      <div>
                                        <div class="irlink-results">
                                          <div class="year-select left-right">
                                            <div class="left current-year"></div>
                                            <div class="right">
                                              <div class="select-box">
                                                <div class="label" @click="dropdownList">
                                                  {{ currentYear
                                                  }}<span class="chevron-arrow-down"></span>
                                                </div>
                                                <ul :class="['dropdown', dropdownBlock]">
                                                  <li>
                                                    <a
                                                      @click="change"
                                                      href="javascript:void(0)"
                                                      data-year="2019"
                                                      data-num="0"
                                                      >2019</a
                                                    >
                                                  </li>
                                                  <li>
                                                    <a
                                                      @click="change"
                                                      href="javascript:void(0)"
                                                      data-year="2018"
                                                      data-num="1"
                                                      >2018</a
                                                    >
                                                  </li>
                                                  <li>
                                                    <a
                                                      @click="change"
                                                      href="javascript:void(0)"
                                                      data-year="2017"
                                                      data-num="2"
                                                      >2017</a
                                                    >
                                                  </li>
                                                  <li>
                                                    <a
                                                      @click="change"
                                                      href="javascript:void(0)"
                                                      data-year="2016"
                                                      data-num="3"
                                                      >2016</a
                                                    >
                                                  </li>
                                                  <li>
                                                    <a
                                                      @click="change"
                                                      href="javascript:void(0)"
                                                      data-year="2015"
                                                      data-num="4"
                                                      >2015</a
                                                    >
                                                  </li>
                                                  <li>
                                                    <a
                                                      @click="change"
                                                      href="javascript:void(0)"
                                                      data-year="2014"
                                                      data-num="5"
                                                      >2014</a
                                                    >
                                                  </li>
                                                  <li>
                                                    <a
                                                      @click="change"
                                                      href="javascript:void(0)"
                                                      data-year="2013"
                                                      data-num="6"
                                                      >2013</a
                                                    >
                                                  </li>
                                                  <li>
                                                    <a
                                                      @click="change"
                                                      href="javascript:void(0)"
                                                      data-year="2012"
                                                      data-num="7"
                                                      >2012</a
                                                    >
                                                  </li>
                                                  <li>
                                                    <a
                                                      @click="change"
                                                      href="javascript:void(0)"
                                                      data-year="2011"
                                                      data-num="8"
                                                      >2011</a
                                                    >
                                                  </li>
                                                  <li>
                                                    <a
                                                      @click="change"
                                                      href="javascript:void(0)"
                                                      data-year="2010"
                                                      data-num="9"
                                                      >2010</a
                                                    >
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="year-row">
                                            <div class="year">
                                              <div class="box">
                                                {{ currentYear }}
                                              </div>
                                            </div>
                                            <ul>
                                              <li class="result-ppt" v-if="currentYear == 2019">
                                                <a
                                                  href="https://app.irlink.com.hk/api/attachment/5dca74483851e14093e32853/5dca74483851e14093e32853.pdf"
                                                  target="__blank"
                                                >
                                                  <img
                                                    src="../assets/sanjiang/2019.jpeg"
                                                    width="336"
                                                    alt="业绩 Interim Results FY2019"
                                                  />
                                                </a>
                                                <div class="info">
                                                  {{ $t('pages.achieve.interimResults') }}
                                                </div>
                                              </li>
                                              <li class="result-ppt" v-if="currentYear == 2018">
                                                <a
                                                  href="https://app.irlink.com.hk/api/attachment/5dca74f73851e14093e32959/5dca74f73851e14093e32959.pdf"
                                                  target="__blank"
                                                >
                                                  <img
                                                    src="../assets/sanjiang/2018-1.jpeg"
                                                    width="336"
                                                    alt="业绩 Interim Results FY2018"
                                                  />
                                                </a>
                                                <div class="info">
                                                  {{ $t('pages.achieve.annualPerformance') }}
                                                </div>
                                              </li>
                                              <li class="result-ppt" v-if="currentYear == 2018">
                                                <a
                                                  href="https://app.irlink.com.hk/api/attachment/5dca754a3851e14093e3296d/5dca754a3851e14093e3296d.pdf"
                                                  target="__blank"
                                                >
                                                  <img
                                                    src="../assets/sanjiang/2018-2.jpeg"
                                                    width="336"
                                                    alt="业绩 Interim Results FY2018"
                                                  />
                                                </a>
                                                <div class="info">
                                                  {{ $t('pages.achieve.interimResults') }}
                                                </div>
                                              </li>
                                              <li class="result-ppt" v-if="currentYear == 2017">
                                                <a
                                                  href="https://app.irlink.com.hk/api/attachment/5dca75973851e14093e32974/5dca75973851e14093e32974.pdf"
                                                  target="__blank"
                                                >
                                                  <img
                                                    src="../assets/sanjiang/2017-1.jpeg"
                                                    width="336"
                                                    alt="业绩 Interim Results FY2017"
                                                  />
                                                </a>
                                                <div class="info">
                                                  {{ $t('pages.achieve.annualPerformance') }}
                                                </div>
                                              </li>
                                              <li class="result-ppt" v-if="currentYear == 2017">
                                                <a
                                                  href="https://app.irlink.com.hk/api/attachment/5dca75dd3851e14093e32980/5dca75dd3851e14093e32980.pdf"
                                                  target="__blank"
                                                >
                                                  <img
                                                    src="../assets/sanjiang/2017-2.jpeg"
                                                    width="336"
                                                    alt="业绩 Interim Results FY2017"
                                                  />
                                                </a>
                                                <div class="info">
                                                  {{ $t('pages.achieve.interimResults') }}
                                                </div>
                                              </li>

                                              <li class="result-ppt" v-if="currentYear == 2016">
                                                <a
                                                  href="https://app.irlink.com.hk/api/attachment/5dca76863851e14093e3298f/5dca76863851e14093e3298f.pdf"
                                                  target="__blank"
                                                >
                                                  <img
                                                    src="../assets/sanjiang/2016-1.jpeg"
                                                    width="336"
                                                    alt="业绩 Interim Results FY2016"
                                                  />
                                                </a>
                                                <div class="info">
                                                  {{ $t('pages.achieve.annualPerformance') }}
                                                </div>
                                              </li>
                                              <li class="result-ppt" v-if="currentYear == 2016">
                                                <a
                                                  href="https://app.irlink.com.hk/api/attachment/5dca76e63851e14093e329a1/5dca76e63851e14093e329a1.pdf"
                                                  target="__blank"
                                                >
                                                  <img
                                                    src="../assets/sanjiang/2016-2.jpeg"
                                                    width="336"
                                                    alt="业绩 Interim Results FY2016"
                                                  />
                                                </a>
                                                <div class="info">
                                                  {{ $t('pages.achieve.interimResults') }}
                                                </div>
                                              </li>

                                              <li class="result-ppt" v-if="currentYear == 2015">
                                                <a
                                                  href="https://app.irlink.com.hk/api/attachment/5dca78213851e14093e329eb/5dca78213851e14093e329eb.pdf"
                                                  target="__blank"
                                                >
                                                  <img
                                                    src="../assets/sanjiang/2015.jpeg"
                                                    width="336"
                                                    alt="业绩 Interim Results FY2015"
                                                  />
                                                </a>
                                                <div class="info">
                                                  {{ $t('pages.achieve.interimResults') }}
                                                </div>
                                              </li>

                                              <li class="result-ppt" v-if="currentYear == 2014">
                                                <a
                                                  href="https://app.irlink.com.hk/api/attachment/5dca77983851e14093e329bf/5dca77983851e14093e329bf.pdf"
                                                  target="__blank"
                                                >
                                                  <img
                                                    src="../assets/sanjiang/2014-1.jpeg"
                                                    width="336"
                                                    alt="业绩 Interim Results FY2014"
                                                  />
                                                </a>
                                                <div class="info">
                                                  {{ $t('pages.achieve.annualPerformance') }}
                                                </div>
                                              </li>
                                              <li class="result-ppt" v-if="currentYear == 2014">
                                                <a
                                                  href="https://app.irlink.com.hk/api/attachment/5dca78703851e14093e32b13/5dca78703851e14093e32b13.pdf"
                                                  target="__blank"
                                                >
                                                  <img
                                                    src="../assets/sanjiang/2014-2.jpeg"
                                                    width="336"
                                                    alt="业绩 Interim Results FY2014"
                                                  />
                                                </a>
                                                <div class="info">
                                                  {{ $t('pages.achieve.interimResults') }}
                                                </div>
                                              </li>

                                              <li class="result-ppt" v-if="currentYear == 2013">
                                                <a
                                                  href="https://app.irlink.com.hk/api/attachment/5dca845f4fa950401db71475/5dca845f4fa950401db71475.pdf"
                                                  target="__blank"
                                                >
                                                  <img
                                                    src="../assets/sanjiang/2013-1.jpeg"
                                                    width="336"
                                                    alt="业绩 Interim Results FY2013"
                                                  />
                                                </a>
                                                <div class="info">
                                                  {{ $t('pages.achieve.annualPerformance') }}
                                                </div>
                                              </li>
                                              <li class="result-ppt" v-if="currentYear == 2013">
                                                <a
                                                  href="https://app.irlink.com.hk/api/attachment/5dca849d4fa950401db7147b/5dca849d4fa950401db7147b.pdf"
                                                  target="__blank"
                                                >
                                                  <img
                                                    src="../assets/sanjiang/2013-2.jpeg"
                                                    width="336"
                                                    alt="业绩 Interim Results FY2013"
                                                  />
                                                </a>
                                                <div class="info">
                                                  {{ $t('pages.achieve.interimResults') }}
                                                </div>
                                              </li>

                                              <li class="result-ppt" v-if="currentYear == 2012">
                                                <a
                                                  href="https://app.irlink.com.hk/api/attachment/5dca850c4fa950401db71490/5dca850c4fa950401db71490.pdf"
                                                  target="__blank"
                                                >
                                                  <img
                                                    src="../assets/sanjiang/2012-1.jpeg"
                                                    width="336"
                                                    alt="业绩 Interim Results FY2012"
                                                  />
                                                </a>
                                                <div class="info">
                                                  {{ $t('pages.achieve.annualPerformance') }}
                                                </div>
                                              </li>
                                              <li class="result-ppt" v-if="currentYear == 2012">
                                                <a
                                                  href="https://app.irlink.com.hk/api/attachment/5dca85494fa950401db7149a/5dca85494fa950401db7149a.pdf"
                                                  target="__blank"
                                                >
                                                  <img
                                                    src="../assets/sanjiang/2012-2.jpeg"
                                                    width="336"
                                                    alt="业绩 Interim Results FY2012"
                                                  />
                                                </a>
                                                <div class="info">
                                                  {{ $t('pages.achieve.interimResults') }}
                                                </div>
                                              </li>

                                              <li class="result-ppt" v-if="currentYear == 2011">
                                                <a
                                                  href="https://app.irlink.com.hk/api/attachment/5dca85884fa950401db7149f/5dca85884fa950401db7149f.pdf"
                                                  target="__blank"
                                                >
                                                  <img
                                                    src="../assets/sanjiang/2011-1.jpeg"
                                                    width="336"
                                                    alt="业绩 Interim Results FY2011"
                                                  />
                                                </a>
                                                <div class="info">
                                                  {{ $t('pages.achieve.annualPerformance') }}
                                                </div>
                                              </li>
                                              <li class="result-ppt" v-if="currentYear == 2011">
                                                <a
                                                  href="https://app.irlink.com.hk/api/attachment/5dca85c44fa950401db714a6/5dca85c44fa950401db714a6.pdf"
                                                  target="__blank"
                                                >
                                                  <img
                                                    src="../assets/sanjiang/2011-2.jpeg"
                                                    width="336"
                                                    alt="业绩 Interim Results FY2011"
                                                  />
                                                </a>
                                                <div class="info">
                                                  {{ $t('pages.achieve.interimResults') }}
                                                </div>
                                              </li>

                                              <li class="result-ppt" v-if="currentYear == 2010">
                                                <a
                                                  href="https://app.irlink.com.hk/api/attachment/5dca86134fa950401db714c1/5dca86134fa950401db714c1.pdf"
                                                  target="__blank"
                                                >
                                                  <img
                                                    src="../assets/sanjiang/2010-1.jpeg"
                                                    width="336"
                                                    alt="业绩 Interim Results FY2010"
                                                  />
                                                </a>
                                                <div class="info">
                                                  {{ $t('pages.achieve.annualPerformance') }}
                                                </div>
                                              </li>
                                              <li class="result-ppt" v-if="currentYear == 2010">
                                                <a
                                                  href="https://app.irlink.com.hk/api/attachment/5dca864f4fa950401db714d4/5dca864f4fa950401db714d4.pdf"
                                                  target="__blank"
                                                >
                                                  <img
                                                    src="../assets/sanjiang/2010-2.jpeg"
                                                    width="336"
                                                    alt="业绩 Interim Results FY2010"
                                                  />
                                                </a>
                                                <div class="info">
                                                  {{ $t('pages.achieve.interimResults') }}
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                          <div class="year-paginator">
                                            <div class="previous" @click="previous"></div>
                                            <ul class="years">
                                              <li :class="status[0]">
                                                <a
                                                  @click="change"
                                                  href="javascript:void(0)"
                                                  data-year="2019"
                                                  data-num="0"
                                                  >2019</a
                                                >
                                              </li>
                                              <li :class="status[1]">
                                                <a
                                                  @click="change"
                                                  href="javascript:void(0)"
                                                  data-year="2018"
                                                  data-num="1"
                                                  >2018</a
                                                >
                                              </li>
                                              <li :class="status[2]">
                                                <a
                                                  @click="change"
                                                  href="javascript:void(0)"
                                                  data-year="2017"
                                                  data-num="2"
                                                  >2017</a
                                                >
                                              </li>
                                              <li :class="status[3]">
                                                <a
                                                  @click="change"
                                                  href="javascript:void(0)"
                                                  data-year="2016"
                                                  data-num="3"
                                                  >2016</a
                                                >
                                              </li>
                                              <li :class="status[4]">
                                                <a
                                                  @click="change"
                                                  href="javascript:void(0)"
                                                  data-year="2015"
                                                  data-num="4"
                                                  >2015</a
                                                >
                                              </li>
                                              <li :class="status[5]">
                                                <a
                                                  @click="change"
                                                  href="javascript:void(0)"
                                                  data-year="2014"
                                                  data-num="5"
                                                  >2014</a
                                                >
                                              </li>
                                              <li :class="status[6]">
                                                <a
                                                  @click="change"
                                                  href="javascript:void(0)"
                                                  data-year="2013"
                                                  data-num="6"
                                                  >2013</a
                                                >
                                              </li>
                                              <li :class="status[7]">
                                                <a
                                                  @click="change"
                                                  href="javascript:void(0)"
                                                  data-year="2012"
                                                  data-num="7"
                                                  >2012</a
                                                >
                                              </li>
                                              <li :class="status[8]">
                                                <a
                                                  @click="change"
                                                  href="javascript:void(0)"
                                                  data-year="2011"
                                                  data-num="8"
                                                  >2011</a
                                                >
                                              </li>
                                              <li :class="status[9]">
                                                <a
                                                  @click="change"
                                                  href="javascript:void(0)"
                                                  data-year="2010"
                                                  data-num="9"
                                                  >2010</a
                                                >
                                              </li>
                                            </ul>
                                            <div class="next" @click="next"></div>
                                          </div>
                                          <form style="display: hidden">
                                            <input
                                              type="hidden"
                                              name="site"
                                              value="https://irlinkdemo.com/sanjiang"
                                            />
                                            <input
                                              type="hidden"
                                              name="context"
                                              value="x+iga+26GDGa01dfybhRKu0CV6Z6cW0YsaoOM71vwXv4PNT7cZ1p1ZSyG2iFexUFeCoUprLAc/eW8V53ZhXBlzuy53NfWSqsGpaD2GtSxRdBQXjetS7l3MNXRVdekH2SBf9djB9LmvBhFUcJhkHb8h0iXnYFy8nVGQqphw4yrW8oP6wA3e8ZRCclsYhpVM+lOOZ8RLDLuTdiYgk9rE0/4w&#x3D;&#x3D;"
                                            />
                                          </form>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>

      <v-footer></v-footer>
    </div>
  </div>
</template>

<script>
import vFooter from '../components/footer';
import vHeader from '../components/header';
export default {
  name: 'first',
  components: {
    vFooter,
    vHeader,
  },

  data() {
    return {
      dropdownBlock: '',
      // 选中 current
      status: ['current', '', '', '', '', 'disnone', 'disnone', 'disnone', 'disnone', 'disnone'],
      currentStatusNum: 0, // 当前第几个
      currentCheck: 0,
      currentYear: 2019,
    };
  },

  methods: {
    dropdownList() {
      if (this.dropdownBlock) {
        this.dropdownBlock = '';
      } else {
        this.dropdownBlock = 'dropdown-block';
      }
    },

    change(e) {
      console.log(e);
      let _year = e.currentTarget.dataset.year;
      let _num = e.currentTarget.dataset.num;
      this.currentYear = _year;
      this.currentCheck = _num;

      for (let j = 0; j < this.status.length; j++) {
        if (this.status[j].indexOf('current') > -1) {
          let st = this.status[j].replace('current', '');
          this.$set(this.status, j, st);
        }
      }

      this.status[_num] = 'current' + this.status[_num];
      this.$set(this.status, _num, this.status[_num]);

      this.dropdownBlock = '';
    },

    previous() {
      this.status[this.currentStatusNum] =
        this.currentCheck == this.currentStatusNum ? 'current disnone' : 'disnone';
      this.$set(this.status, this.currentStatusNum, this.status[this.currentStatusNum]);
      this.status[this.currentStatusNum + 5] = '';
      this.$set(this.status, this.currentStatusNum + 5, this.status[this.currentStatusNum + 5]);

      if (this.currentStatusNum < 4) {
        this.currentStatusNum++;
      }
    },

    next() {
      this.status[this.currentStatusNum] = '';
      this.$set(this.status, this.currentStatusNum, this.status[this.currentStatusNum]);
      this.status[this.currentStatusNum + 5] =
        this.currentCheck == this.currentStatusNum ? 'current disnone' : 'disnone';
      this.$set(this.status, this.currentStatusNum + 5, this.status[this.currentStatusNum + 5]);

      if (this.currentStatusNum > 0) {
        this.currentStatusNum--;
      }
      console.log(this.currentStatusNum);
    },
  },
};
</script>

<style scoped>
.irlink-results {
  position: relative;
}
.irlink-results a,
.irlink-results a:hover {
  box-shadow: none;
}
.irlink-results .year-select {
  margin-bottom: 12px;
  text-align: right;
}
.irlink-results .left-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.irlink-results .select-box {
  display: inline-block;
  position: relative;
  margin: 0 0 0 8px;
  user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}
.irlink-results .select-box .label {
  position: relative;
  display: inline-block;
  padding: 4px 50px 4px 30px;
  border: 2px solid #888;
  cursor: pointer;
  font-size: 20px;
}
.irlink-results .select-box .dropdown {
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  background-color: #fff;
  border: 1px solid #888;
  z-index: 1;
}

.irlink-results .select-box .dropdown.dropdown-block {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  background-color: #fff;
  border: 1px solid #888;
  z-index: 1;
}

.irlink-results .select-box .dropdown li {
  text-align: center;
  cursor: pointer;
  padding: 0;
  margin: 0;
}
.irlink-results .select-box .dropdown li a {
  display: block;
  width: 100%;
  padding: 4px 12px;
  font-size: 16px;
  color: #70ad47;
}
.irlink-results .select-box .dropdown li:hover {
  background-color: #70ad47;
}
.irlink-results .select-box .dropdown li:hover a {
  color: #fff;
}
.irlink-results .chevron-arrow-down {
  display: inline-block;
  border-right: 3px solid #888;
  border-bottom: 3px solid #888;
  width: 12px;
  height: 12px;
  transform: rotate(45deg);
  position: absolute;
  right: 16px;
  top: 50%;
  margin-top: -8px;
}
.irlink-results .year-row {
  display: flex;
  flex-direction: row;
  /*flex-wrap: wrap;*/
  padding-top: 24px;
  padding-bottom: 24px;
  /*border-bottom: 1px solid #424242;*/
}
.irlink-results .year {
  width: 26%;
  align-items: center;
  color: #70ad47;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
}
.irlink-results .year .box {
  font-size: 36px;
  line-height: 40px;
  font-family: 'Helvetica Neue', 'Hiragino Sans GB', 'Microsoft YaHei', 黑体, Arial, sans-serif;
  border-left: 8px #c4c4c4 solid;
  padding-left: 16px;
}
.irlink-results ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  /*flex-wrap: wrap;*/
}
.irlink-results ul .result-ppt {
  list-style: none;
  padding: 10px;
  margin: 0;
  display: flex;
  flex-direction: column;
}
.irlink-results .year-paginator {
  display: flex;
  justify-content: center;
  align-items: center;
}
.irlink-results .year-paginator li {
  padding: 8px;
}
.irlink-results .year-paginator li a {
  color: #000;
  text-decoration: none;
  font-size: 24px;
}
.irlink-results .year-paginator li.current a {
  color: #70ad47;
  font-weight: bold;
}
.irlink-results .previous {
  width: 24px;
  height: 24px;
  border-top: 5px #70ad47 solid;
  border-left: 5px #70ad47 solid;
  transform: rotate(-45deg);
  cursor: pointer;
}
.irlink-results .next {
  width: 24px;
  height: 24px;
  border-top: 5px #70ad47 solid;
  border-right: 5px #70ad47 solid;
  transform: rotate(45deg);
  cursor: pointer;
}

.disnone {
  display: none;
}
</style>
