<template>
  <div
    class="
      page-template-default page page-id-33
      fl-builder fl-framework-base fl-preset-default fl-full-width
    "
    itemscope="itemscope"
    itemtype="https://schema.org/WebPage"
  >
    <div class="fl-page">
      <v-header></v-header>
      <div class="fl-page-content" itemprop="mainContentOfPage">
        <div class="fl-content-full container">
          <div class="row">
            <div class="fl-content col-md-12">
              <article
                class="fl-post post-119 page type-page status-publish hentry"
                id="fl-post-119"
                itemscope="itemscope"
                itemtype="https://schema.org/CreativeWork"
              >
                <div class="fl-post-content clearfix" itemprop="text">
                  <div
                    class="
                      fl-builder-content
                      fl-builder-content-119
                      fl-builder-content-primary
                      fl-builder-global-templates-locked
                    "
                    data-post-id="119"
                  >
                    <div
                      class="
                        fl-row
                        fl-row-full-width
                        fl-row-bg-photo
                        fl-node-5da354ca49f92
                        fl-visible-desktop-medium
                      "
                      data-node="5da354ca49f92"
                    >
                      <div class="fl-row-content-wrap">
                        <div class="fl-row-content fl-row-fixed-width fl-node-content">
                          <div class="fl-col-group fl-node-5da354ca4d850" data-node="5da354ca4d850">
                            <div
                              class="fl-col fl-node-5da354ca4da2d fl-col-has-cols"
                              data-node="5da354ca4da2d"
                            >
                              <div class="fl-col-content fl-node-content">
                                <div
                                  class="fl-col-group fl-node-5da361f7ea075 fl-col-group-nested"
                                  data-node="5da361f7ea075"
                                >
                                  <div class="fl-col fl-node-5da361f7ea28b" data-node="5da361f7ea28b">
                                    <div class="fl-col-content fl-node-content">
                                      <div
                                        class="fl-module fl-module-rich-text fl-node-5da355407229c"
                                        data-node="5da355407229c"
                                      >
                                        <div class="fl-module-content fl-node-content">
                                          <div class="fl-rich-text">
                                            <p>&nbsp;</p>
                                            <p>&nbsp;</p>
                                            <p>&nbsp;</p>
                                            <p>&nbsp;</p>
                                            <p>&nbsp;</p>
                                            <p>&nbsp;</p>
                                            <p>&nbsp;</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="fl-row fl-row-fixed-width fl-row-bg-none fl-node-5da7cd2428816"
                      data-node="5da7cd2428816"
                    >
                      <div class="fl-row-content-wrap">
                        <div class="fl-row-content fl-row-fixed-width fl-node-content">
                          <div class="fl-col-group fl-node-5dca607e58c79" data-node="5dca607e58c79">
                            <div class="fl-col fl-node-5dca607e58d64" data-node="5dca607e58d64">
                              <div class="fl-col-content fl-node-content">
                                <div
                                  class="fl-module fl-module-rich-text fl-node-5dca607e58beb"
                                  data-node="5dca607e58beb"
                                >
                                  <div class="fl-module-content fl-node-content">
                                    <div class="fl-rich-text">
                                      <h1>
                                        {{ $t('pages.notice.topTitle') }}
                                      </h1>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="fl-col-group fl-node-5da7cd242b3a2" data-node="5da7cd242b3a2">
                            <div class="fl-col fl-node-5da7cd242b54b" data-node="5da7cd242b54b">
                              <div class="fl-col-content fl-node-content">
                                <div
                                  class="fl-module fl-module-rich-text fl-node-5da7cd30de254"
                                  data-node="5da7cd30de254"
                                >
                                  <div class="fl-module-content fl-node-content">
                                    <div class="fl-rich-text">
                                      <div>
                                        <div class="irlink-hkex-news-root">
                                          <ul class="news">
                                            <li class="irlink-hkex-news-item">
                                              <div class="irlink-hkex-news-content">
                                                <div class="arrow-right"></div>
                                                <a
                                                  :href="
                                                    $i18n.locale === 'zh_CN'
                                                      ? 'https://d24gqd1rj4a17u.cloudfront.net/sanjiang-prd/Dissemination_of_Corporate_Communications_TC.pdf'
                                                      : 'https://d24gqd1rj4a17u.cloudfront.net/sanjiang-prd/Dissemination_of_Corporate_Communications_EN.pdf'
                                                  "
                                                  target="_blank"
                                                  class="irlink-hkex-news-link"
                                                >
                                                  {{ $t('pages.notice.topItem1') }}
                                                </a>
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- 公司公告 -->
                    <div
                      class="fl-row fl-row-fixed-width fl-row-bg-none fl-node-5da7cd2428816"
                      data-node="5da7cd2428816"
                    >
                      <div class="fl-row-content-wrap">
                        <div class="fl-row-content fl-row-fixed-width fl-node-content">
                          <div class="fl-col-group fl-node-5dca607e58c79" data-node="5dca607e58c79">
                            <div class="fl-col fl-node-5dca607e58d64" data-node="5dca607e58d64">
                              <div class="fl-col-content fl-node-content">
                                <div
                                  class="fl-module fl-module-rich-text fl-node-5dca607e58beb"
                                  data-node="5dca607e58beb"
                                >
                                  <div class="fl-module-content fl-node-content">
                                    <div class="fl-rich-text">
                                      <h1>
                                        {{ $t('pages.notice.title') }}
                                      </h1>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="fl-col-group fl-node-5da7cd242b3a2" data-node="5da7cd242b3a2">
                            <div class="fl-col fl-node-5da7cd242b54b" data-node="5da7cd242b54b">
                              <div class="fl-col-content fl-node-content">
                                <div
                                  class="fl-module fl-module-rich-text fl-node-5da7cd30de254"
                                  data-node="5da7cd30de254"
                                >
                                  <div class="fl-module-content fl-node-content">
                                    <div class="fl-rich-text">
                                      <div>
                                        <div class="irlink-hkex-news-root">
                                          <div class="year-select left-right">
                                            <div class="left current-year">
                                              {{ currentYear }}
                                            </div>
                                            <div class="right">
                                              <div class="select-box">
                                                <div class="label" @click="dropdownList">
                                                  {{ currentYear }}
                                                  <span class="chevron-arrow-down"> </span>
                                                </div>
                                                <ul :class="['dropdown', dropdownBlock]">
                                                  <li
                                                    v-for="(item, index) in yearSelectList"
                                                    :key="index"
                                                  >
                                                    <a
                                                      @click="changeYear"
                                                      href="javascript:void(0)"
                                                      :data-year="item"
                                                    >
                                                      {{ item }}
                                                    </a>
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                          </div>
                                          <ul class="news">
                                            <li
                                              class="irlink-hkex-news-item"
                                              v-for="(item, index) in documents"
                                              :key="index"
                                            >
                                              <div class="irlink-hkex-news-content">
                                                <div class="arrow-right"></div>
                                                <a
                                                  :href="item.url"
                                                  target="_blank"
                                                  class="irlink-hkex-news-link"
                                                >
                                                  {{ item.title }}
                                                </a>
                                              </div>
                                              <div class="irlink-hkex-news-date">
                                                {{ item.date }}
                                              </div>
                                            </li>
                                          </ul>
                                          <ul class="paginator">
                                            <li class="first" @click="first">
                                              <a
                                                href="javascript:void(0)"
                                                data-page=""
                                                style="cursor: initial"
                                              >
                                                <div class="paging-button" style="top: 3px">
                                                  <div
                                                    class="first-last"
                                                    :style="prev == 'tclick' ? sclickLine : unclickLine"
                                                  ></div>
                                                  <div
                                                    class="left-triangle"
                                                    :style="prev == 'tclick' ? lsclick : lunclick"
                                                  ></div>
                                                </div>
                                              </a>
                                            </li>
                                            <li class="previous" @click="previous">
                                              <a href="javascript:void(0)" style="cursor: initial">
                                                <div class="paging-button">
                                                  <div
                                                    class="left-triangle"
                                                    :style="prev == 'tclick' ? lsclick : lunclick"
                                                  ></div>
                                                </div>
                                              </a>
                                            </li>
                                            <li
                                              v-for="(item, index) in getPageNum()"
                                              :key="item"
                                              @click="changePage"
                                              :class="[
                                                {
                                                  current: currentPage == item ? true : false,
                                                },
                                                page,
                                              ]"
                                              :data-page="item"
                                            >
                                              <a href="javascript:void(0)">
                                                {{ index + 1 }}
                                              </a>
                                            </li>
                                            <li class="next" @click="next">
                                              <a
                                                href="javascript:void(0)"
                                                data-page="2"
                                                style="cursor: pointer"
                                              >
                                                <div class="paging-button">
                                                  <div
                                                    class="right-triangle"
                                                    :style="next1 == 'tclick' ? rsclick : runclick"
                                                  ></div>
                                                </div>
                                              </a>
                                            </li>
                                            <li class="last" @click="last">
                                              <a
                                                href="javascript:void(0)"
                                                data-page="3"
                                                style="cursor: pointer"
                                              >
                                                <div class="paging-button">
                                                  <div
                                                    class="right-triangle"
                                                    :style="next1 == 'tclick' ? rsclick : runclick"
                                                  ></div>
                                                  <div
                                                    class="first-last"
                                                    :style="next1 == 'tclick' ? sclickLine : unclickLine"
                                                  ></div>
                                                </div>
                                              </a>
                                            </li>
                                          </ul>
                                          <form style="display: none">
                                            <input
                                              type="hidden"
                                              name="context"
                                              value='{"lang":"sc","limit":10,"newsType":"","paging":true,"filters":{"mode":"classic","year":"2021","stockCode":"2198 HK","newsType":""},"dateFormat":"YYYY年MM月DD日","startYear":"2010","template":"sanjiang-news-main","rootClass":"","byAjax":true}'
                                            /><input
                                              type="hidden"
                                              name="site"
                                              value="https://irlinkdemo.com/sanjiang"
                                            />
                                          </form>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
        <br />
        <br />
      </div>
      <v-footer></v-footer>
    </div>
  </div>
</template>

<script>
import vFooter from '../components/footer';
import vHeader from '../components/header';
import api from '../api';
import i18n from '@/locale';

export default {
  name: 'first',
  components: {
    vFooter,
    vHeader,
  },

  data() {
    return {
      dropdownBlock: '',
      currentYear: undefined,
      currentPage: 1,
      nextPage: this.currentPage + 1,
      prev: 'unclick',
      next1: 'tclick',
      lunclick: 'border-color: transparent #888 transparent transparent;',
      lsclick: 'border-color: transparent #70ad47 transparent transparent;',

      runclick: 'border-color: transparent transparent transparent #888',
      rsclick: 'border-color:  transparent transparent transparent #70ad47',
      unclickLine: 'background-color: #888',
      sclickLine: 'background-color: #70ad47',

      fixed: false,
      documents: [],
      pageSize: 10,
      // 年份列表
      yearSelectList: [],
    };
  },
  watch: {
    '$i18n.locale': function () {
      this.getDocuments();
    },
  },
  mounted() {
    this.initData();
  },
  methods: {
    dropdownList() {
      if (this.dropdownBlock) {
        this.dropdownBlock = '';
      } else {
        this.dropdownBlock = 'dropdown-block';
      }
    },
    generateYearList() {
      const currentYear = new Date().getFullYear();
      // 赋值当前年份
      this.currentYear = currentYear;
      const minYear = 2017;
      const yearList = [];
      for (let year = currentYear; year >= minYear; year--) {
        yearList.push(year);
      }
      return yearList;
    },
    initData() {
      // 从当前年份到最早年份（2017）的年份列表
      const years = this.generateYearList();
      this.yearSelectList = years;
      this.getDocuments();
    },
    async getDocuments() {
      const locale = i18n.locale != 'zh_CN' ? 'en' : 'sc';
      const year = this.currentYear;
      const pageNum = this.currentPage;
      const result = await api.document.get(locale, year, pageNum, this.pageSize);
      this.documents = result.data;
      this.total = result.total;
      this.resetArrow();
    },
    changeYear(e) {
      let _year = e.currentTarget.dataset.year;
      this.currentYear = _year;
      this.currentPage = 1;
      this.getDocuments();
    },
    changePage(e) {
      let _page = e.currentTarget.dataset.page;
      this.resetArrow();
      this.currentPage = _page;
      this.getDocuments();
    },
    getPageNum() {
      return Math.ceil(this.total / this.pageSize || 1);
    },
    resetArrow() {
      const isFirstPage = this.currentPage == 1;
      const isLastPage = this.total - (this.currentPage - 1) * this.pageSize <= this.pageSize;
      if (isFirstPage && isLastPage) {
        this.prev = 'unclick';
        this.next1 = 'unclick';
      } else if (isFirstPage) {
        this.prev = 'unclick';
        this.next1 = 'tclick';
      } else if (isLastPage) {
        this.prev = 'tclick';
        this.next1 = 'unclick';
      } else {
        this.prev = 'tclick';
        this.next1 = 'tclick';
      }
    },
    first() {
      if (this.prev == 'unclick') {
        return;
      }
      if (this.currentPage != 1) {
        this.currentPage = 1;
        this.prev = 'unclick';
        this.next1 = 'tclick';
        this.getDocuments();
      }
    },
    last() {
      if (this.next1 == 'unclick') {
        return;
      }

      const isLastPage = this.total - (this.currentPage - 1) * this.pageSize <= this.pageSize;
      if (!isLastPage) {
        this.currentPage = this.getPageNum();
        this.next1 = 'unclick';
        this.prev = 'tclick';
        this.getDocuments();
      }
    },
    previous() {
      if (this.prev == 'unclick') {
        return;
      }
      this.currentPage--;

      if (this.currentPage == 1) {
        this.prev = 'unclick';
        this.next1 = 'tclick';
      }
      this.getDocuments();
    },
    next() {
      if (this.next1 == 'unclick') {
        return;
      }
      this.currentPage++;

      const isLastPage = this.total - this.currentPage * this.pageSize <= this.pageSize;
      if (isLastPage) {
        this.prev = 'tclick';
        this.next1 = 'unclick';
      }
      this.getDocuments();
    },
  },
};
</script>

<style scoped>
.irlink-hkex-news-root {
  font-size: 17pt;
  position: relative;
  bottom: 30px;
}
.irlink-hkex-news-root a {
  color: #222;
}
.irlink-hkex-news-root .irlink-hkex-news-item:nth-child(odd) {
  background-color: transparent;
}
.irlink-hkex-news-root ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
.irlink-hkex-news-root .irlink-hkex-news-item {
  padding: 8px 8px;
  display: block;
  border-bottom: 2px #d4faf7 solid;
}
.irlink-hkex-news-root .irlink-hkex-news-item:hover {
  background-color: #70ad47;
}
.irlink-hkex-news-root a,
.irlink-hkex-news-root a:hover {
  text-decoration: none;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}
.irlink-hkex-news-root .arrow-left {
  border-top: 3px #222 solid;
  border-left: 3px #222 solid;
  background-color: transparent;
  height: 10px;
  width: 10px;
  transform: rotate(-45deg);
  display: inline-block;
  margin-left: 2px;
}
.irlink-hkex-news-root .arrow-right {
  border-top: 3px #222 solid;
  border-right: 3px #222 solid;
  background-color: transparent;
  height: 10px;
  width: 10px;
  transform: rotate(45deg);
  display: inline-block;
  margin-right: 2px;
}
.irlink-hkex-news-root .irlink-hkex-news-item .irlink-hkex-news-content {
  color: #000;
  font-size: smaller;
  display: flex;
  align-items: center;
}
/*.irlink-hkex-news-root .irlink-hkex-news-item .irlink-hkex-news-content a:hover {*/
/*  color: #2386EE;*/
/*}*/
.irlink-hkex-news-root .irlink-hkex-news-date {
  font-size: small;
  padding-top: 10px;
  padding-bottom: 15px;
  color: #000;
}
.irlink-hkex-news-root .irlink-hkex-news-link {
  margin-left: 10px;
}
.irlink-hkex-news-root .year-select {
  margin-bottom: 12px;
  text-align: right;
}
.irlink-hkex-news-root .left-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.irlink-hkex-news-root .select-box {
  display: inline-block;
  position: relative;
  margin: 0 0 0 8px;
  user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  z-index: 1;
}
.irlink-hkex-news-root .select-box .label {
  position: relative;
  display: inline-block;
  padding: 4px 50px 4px 30px;
  border: 2px solid #888;
  cursor: pointer;
  font-size: 20px;
}
.irlink-hkex-news-root .select-box .dropdown {
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  background-color: #fff;
  border: 1px solid #888;
}
.irlink-hkex-news-root .select-box .dropdown.dropdown-block {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  background-color: #fff;
  border: 1px solid #888;
}
.irlink-hkex-news-root .select-box .dropdown li {
  text-align: center;
  cursor: pointer;
}
.irlink-hkex-news-root .select-box .dropdown li a {
  display: block;
  padding: 4px 12px;
  font-size: 16px;
}
.irlink-hkex-news-root .select-box .dropdown li:hover {
  background-color: #70ad47;
}
.irlink-hkex-news-root .chevron-arrow-down {
  display: inline-block;
  border-right: 3px solid #888;
  border-bottom: 3px solid #888;
  width: 12px;
  height: 12px;
  transform: rotate(45deg);
  position: absolute;
  right: 16px;
  top: 50%;
  margin-top: -8px;
}
.irlink-hkex-news-root .paginator {
  text-align: center;
  padding-top: 20px;
}
.irlink-hkex-news-root .paginator li {
  display: inline-block;
  margin-right: 5px;
}
.irlink-hkex-news-root .paginator li a {
  display: inline-block;
  padding: 2px 4px;
  color: #222;
  font-size: 18px;
}
.irlink-hkex-news-root .paginator li.current a {
  display: inline-block;
  padding: 2px 4px;
  font-weight: bold;
}
.irlink-hkex-news-root .current-year {
  color: #70ad47;
  font-size: 30px;
}
.irlink-hkex-news-root .paging-button {
  position: relative;
  top: 1px;
  display: flex;
}
.irlink-hkex-news-root .first-last {
  width: 3px;
  height: 18px;
  background-color: #70ad47;
}
.irlink-hkex-news-root .left-triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 13.84px 8px 0;
  border-color: transparent #70ad47 transparent transparent;
}
.irlink-hkex-news-root .right-triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 0 8px 13.84px;
  border-color: transparent transparent transparent #70ad47;
}
</style>
