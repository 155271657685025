<template>
  <div
    class="
      page-template-default page page-id-33
      fl-builder fl-framework-base fl-preset-default fl-full-width
    "
    itemscope="itemscope"
    itemtype="https://schema.org/WebPage"
  >
    <div class="fl-page">
      <v-header></v-header>
      <div class="fl-page-content" itemprop="mainContentOfPage">
        <div class="fl-content-full container">
          <div class="row">
            <div class="fl-content col-md-12">
              <article
                class="fl-post post-79 page type-page status-publish hentry"
                id="fl-post-79"
                itemscope="itemscope"
                itemtype="https://schema.org/CreativeWork"
              >
                <div class="fl-post-content clearfix" itemprop="text">
                  <div
                    class="
                      fl-builder-content
                      fl-builder-content-79
                      fl-builder-content-primary
                      fl-builder-global-templates-locked
                    "
                    data-post-id="79"
                  >
                    <div
                      class="
                        fl-row
                        fl-row-full-width
                        fl-row-bg-photo
                        fl-node-5db100ea953e9
                        fl-visible-desktop-medium
                      "
                      data-node="5db100ea953e9"
                    >
                      <div class="fl-row-content-wrap">
                        <div class="fl-row-content fl-row-fixed-width fl-node-content">
                          <div class="fl-col-group fl-node-5db100ea98a06" data-node="5db100ea98a06">
                            <div class="fl-col fl-node-5db100ea98c64" data-node="5db100ea98c64">
                              <div class="fl-col-content fl-node-content">
                                <div
                                  class="fl-module fl-module-rich-text fl-node-5dca5e87ef8e0"
                                  data-node="5dca5e87ef8e0"
                                >
                                  <div class="fl-module-content fl-node-content">
                                    <div class="fl-rich-text">
                                      <p>&nbsp;</p>
                                      <p>&nbsp;</p>
                                      <p>&nbsp;</p>
                                      <p>&nbsp;</p>
                                      <p>&nbsp;</p>
                                      <p>&nbsp;</p>
                                      <p>&nbsp;</p>
                                      <p>&nbsp;</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="fl-row fl-row-fixed-width fl-row-bg-none fl-node-5da35b06ea5d0"
                      data-node="5da35b06ea5d0"
                    >
                      <div class="fl-row-content-wrap">
                        <div class="fl-row-content fl-row-fixed-width fl-node-content">
                          <div class="fl-col-group fl-node-5da364587b5c7" data-node="5da364587b5c7">
                            <div class="fl-col fl-node-5da364587b708" data-node="5da364587b708">
                              <div class="fl-col-content fl-node-content">
                                <div
                                  class="fl-module fl-module-rich-text fl-node-5da364587b494 fl-fade-in"
                                  data-node="5da364587b494"
                                  data-animation-delay="0.0"
                                >
                                  <div class="fl-module-content fl-node-content">
                                    <div class="fl-rich-text">
                                      <h1>
                                        <span style="color: #70ad47">{{
                                          $t('pages.framework.title')
                                        }}</span>
                                      </h1>
                                      <h1></h1>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="fl-col-group fl-node-5da35b06f07aa" data-node="5da35b06f07aa">
                            <div class="fl-col fl-node-5da35b06f0917" data-node="5da35b06f0917">
                              <div class="fl-col-content fl-node-content">
                                <div
                                  class="fl-module fl-module-photo fl-node-5da3643b1a4af"
                                  data-node="5da3643b1a4af"
                                  data-animation-delay="0.0"
                                >
                                  <div class="fl-module-content fl-node-content">
                                    <div
                                      class="fl-photo fl-photo-align-center"
                                      itemscope
                                      itemtype="https://schema.org/ImageObject"
                                    >
                                      <div class="fl-photo-content fl-photo-img-jpg">
                                        <img
                                          class="fl-photo-img wp-image-458 size-full"
                                          src="https://irlinkdemo.com/sanjiang/wp-content/uploads/2019/11/ScreenShot1447.jpg"
                                          alt="ScreenShot1447"
                                          itemprop="image"
                                          height="541"
                                          width="1264"
                                          title="ScreenShot1447"
                                          srcset="
                                            https://irlinkdemo.com/sanjiang/wp-content/uploads/2019/11/ScreenShot1447.jpg          1264w,
                                            https://irlinkdemo.com/sanjiang/wp-content/uploads/2019/11/ScreenShot1447-300x128.jpg   300w,
                                            https://irlinkdemo.com/sanjiang/wp-content/uploads/2019/11/ScreenShot1447-768x329.jpg   768w,
                                            https://irlinkdemo.com/sanjiang/wp-content/uploads/2019/11/ScreenShot1447-1024x438.jpg 1024w
                                          "
                                          sizes="(max-width: 1264px) 100vw, 1264px"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  class="fl-module fl-module-rich-text fl-node-5dca6f2816524"
                                  data-node="5dca6f2816524"
                                >
                                  <div class="fl-module-content fl-node-content">
                                    <div class="fl-rich-text">
                                      <h2>
                                        {{ $t('pages.framework.directorResume.title') }}
                                      </h2>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  class="fl-module fl-module-rich-text fl-node-5da35b0badb02"
                                  data-node="5da35b0badb02"
                                  data-animation-delay="0.0"
                                >
                                  <div class="fl-module-content fl-node-content">
                                    <div class="fl-rich-text">
                                      <!-- <p> -->
                                      <table id="tablepress-1" class="tablepress tablepress-id-1">
                                        <tbody class="row-hover">
                                          <tr class="row-1 odd">
                                            <td class="column-1">
                                              {{ $t('pages.framework.directorResume.director1a') }}
                                            </td>
                                            <td class="column-2">
                                              {{ $t('pages.framework.directorResume.director1b') }}
                                            </td>
                                          </tr>
                                          <tr class="row-2 even">
                                            <td class="column-1">
                                              {{ $t('pages.framework.directorResume.director2a') }}
                                            </td>
                                            <td class="column-2">
                                              {{ $t('pages.framework.directorResume.director2b') }}<br />
                                              {{ $t('pages.framework.directorResume.director2c') }}<br />
                                            </td>
                                          </tr>
                                          <tr class="row-3 odd">
                                            <td class="column-1">
                                              {{ $t('pages.framework.directorResume.director3a') }}
                                            </td>
                                            <td class="column-2">
                                              {{ $t('pages.framework.directorResume.director3b') }}
                                            </td>
                                          </tr>
                                          <tr class="row-4 even">
                                            <td class="column-1">
                                              {{ $t('pages.framework.directorResume.director4a') }}
                                            </td>
                                            <td class="column-2">
                                              {{ $t('pages.framework.directorResume.director4b') }}
                                            </td>
                                          </tr>
                                          <tr class="row-5 odd">
                                            <td class="column-1">
                                              {{ $t('pages.framework.directorResume.director5a') }}
                                            </td>
                                            <td class="column-2">
                                              {{ $t('pages.framework.directorResume.director5b') }}
                                            </td>
                                          </tr>
                                          <tr class="row-6 even">
                                            <td class="column-1">
                                              {{ $t('pages.framework.directorResume.director6a') }}
                                            </td>
                                            <td class="column-2">
                                              {{ $t('pages.framework.directorResume.director6b') }}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <!-- </p> -->
                                      <p>&nbsp;</p>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  class="fl-module fl-module-rich-text fl-node-5dca6ec495952"
                                  data-node="5dca6ec495952"
                                >
                                  <div class="fl-module-content fl-node-content">
                                    <div class="fl-rich-text">
                                      <h2>
                                        {{ $t('pages.framework.seniorManagementResume.title') }}
                                      </h2>
                                      <!-- <p> -->
                                      <table id="tablepress-2" class="tablepress tablepress-id-2">
                                        <tbody class="row-hover">
                                          <tr class="row-1 odd">
                                            <td class="column-1">
                                              {{ $t('pages.framework.seniorManagementResume.senior1a') }}
                                            </td>
                                            <td class="column-2">
                                              {{ $t('pages.framework.seniorManagementResume.senior1b') }}
                                            </td>
                                          </tr>
                                          <tr class="row-2 even">
                                            <td class="column-1">
                                              {{ $t('pages.framework.seniorManagementResume.senior2a') }}
                                            </td>
                                            <td class="column-2">
                                              {{ $t('pages.framework.seniorManagementResume.senior2b') }}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <!-- </p> -->
                                    </div>
                                  </div>
                                </div>
                                <div
                                  class="fl-module fl-module-rich-text fl-node-5dca6ed442e23"
                                  data-node="5dca6ed442e23"
                                >
                                  <div class="fl-module-content fl-node-content">
                                    <div class="fl-rich-text"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>

      <v-footer></v-footer>
    </div>
  </div>
</template>

<script>
import vFooter from '../components/footer';
import vHeader from '../components/header';
export default {
  name: 'first',
  components: {
    vFooter,
    vHeader,
  },

  data() {
    return {};
  },

  methods: {
    jumpPage() {
      this.$router.push('helloworld');
    },
  },
};
</script>

<style scoped></style>
