<template>
  <div :class="fixed" itemscope="itemscope">
    <div class="fl-page-header-wrap">
      <div class="fl-page-header-container container">
        <div class="fl-page-header-row row">
          <div class="col-sm-12 col-md-4 fl-page-header-logo-col">
            <div class="fl-page-header-logo" itemscope="itemscope">
              <router-link to="/"
                ><img
                  class="fl-logo-img"
                  itemscope
                  src="../assets/sanjiang/logo.jpg"
                  data-retina="../assets/sanjiang/logo.jpg"
                  data-mobile="../assets/sanjiang/logo.jpg"
                  alt="中国三江精细化工有限公司" />
                <meta itemprop="name" content="中国三江精细化工有限公司"
              /></router-link>
            </div>
          </div>
          <div class="col-sm-12 col-md-8 fl-page-nav-col">
            <div class="fl-page-nav-wrap">
              <nav
                class="fl-page-nav fl-nav navbar navbar-default navbar-expand-md"
                aria-label="Header Menu"
                itemscope="itemscope"
              >
                <button
                  type="button"
                  @click="openMenu"
                  class="navbar-toggle navbar-toggler"
                  data-toggle="collapse"
                  data-target=".fl-page-nav-collapse"
                >
                  <span>Menu</span>
                </button>
                <div :class="navClass">
                  <ul id="menu-main-nav-1" class="nav navbar-nav navbar-right menu">
                    <li
                      class="
                        menu-item
                        menu-item-type-post_type
                        menu-item-object-page menu-item-home
                        current-menu-item
                        page_item page-item-5
                        current_page_item
                        menu-item-75
                        nav-item
                      "
                    >
                      <router-link to="/" class="nav-link">{{ $t('pages.header.menu_1') }}</router-link>
                    </li>
                    <li
                      @click="slideLi"
                      @mouseover="mouserMenu"
                      @mouseout="mouserOut"
                      data-type="1"
                      :class="menu1"
                    >
                      <a href="#">{{ $t('pages.header.menu_2') }}</a>
                      <ul class="sub-menu">
                        <li
                          class="
                            menu-item
                            menu-item-type-post_type
                            menu-item-object-page menu-item-108
                            nav-item
                          "
                        >
                          <router-link to="/about" class="nav-link">{{
                            $t('pages.header.subMenu_2a')
                          }}</router-link>
                        </li>
                        <!-- 公司架构 -->
                        <!-- <li
                          class="
                            menu-item
                            menu-item-type-post_type
                            menu-item-object-page menu-item-107
                            nav-item
                          "
                        >
                          <router-link to="/framework" class="nav-link">{{
                            $t("pages.header.subMenu_2b")
                          }}</router-link>
                        </li> -->
                        <li
                          class="
                            menu-item
                            menu-item-type-post_type
                            menu-item-object-page menu-item-106
                            nav-item
                          "
                        >
                          <router-link to="/mileage" class="nav-link">{{
                            $t('pages.header.subMenu_2c')
                          }}</router-link>
                        </li>
                      </ul>
                    </li>
                    <li
                      @click="slideLi"
                      @mouseover="mouserMenu"
                      @mouseout="mouserOut"
                      data-type="2"
                      :class="menu2"
                    >
                      <a href="#" class="nav-link">{{ $t('pages.header.menu_3') }}</a>
                      <ul class="sub-menu">
                        <li
                          class="
                            menu-item
                            menu-item-type-post_type
                            menu-item-object-page menu-item-110
                            nav-item
                          "
                        >
                          <router-link to="/industry" class="nav-link">{{
                            $t('pages.header.subMenu_3a')
                          }}</router-link>
                        </li>
                        <li
                          class="
                            menu-item
                            menu-item-type-post_type
                            menu-item-object-page menu-item-113
                            nav-item
                          "
                        >
                          <router-link to="/product" class="nav-link">{{
                            $t('pages.header.subMenu_3b')
                          }}</router-link>
                        </li>
                      </ul>
                    </li>
                    <li
                      @click="slideLi"
                      @mouseover="mouserMenu"
                      @mouseout="mouserOut"
                      data-type="3"
                      :class="menu3"
                    >
                      <a href="#" class="nav-link">{{ $t('pages.header.menu_4') }}</a>
                      <ul class="sub-menu">
                        <!-- 财务资料 -->
                        <!-- <li
                          class="
                            menu-item
                            menu-item-type-post_type
                            menu-item-object-page menu-item-132
                            nav-item
                          "
                        >
                          <router-link to="/money" class="nav-link">{{
                            $t("pages.header.subMenu_4a")
                          }}</router-link>
                        </li> -->

                        <!-- 业绩演示资料 -->
                        <!-- <li
                          class="
                            menu-item
                            menu-item-type-post_type
                            menu-item-object-page menu-item-128
                            nav-item
                          "
                        >
                          <router-link to="/achieve" class="nav-link">{{
                            $t("pages.header.subMenu_4b")
                          }}</router-link>
                        </li> -->
                        <li
                          class="
                            menu-item
                            menu-item-type-post_type
                            menu-item-object-page menu-item-127
                            nav-item
                          "
                        >
                          <router-link to="/notice" class="nav-link">{{
                            $t('pages.header.subMenu_4c')
                          }}</router-link>
                        </li>
                      </ul>
                    </li>
                    <li
                      @click="slideLi"
                      @mouseover="mouserMenu"
                      @mouseout="mouserOut"
                      data-type="4"
                      :class="menu4"
                    >
                      <!-- 新闻中心 -->
                      <!-- <a href="#" class="nav-link">{{
                        $t("pages.header.menu_5")
                      }}</a> -->
                      <ul class="sub-menu">
                        <li
                          class="
                            menu-item
                            menu-item-type-post_type
                            menu-item-object-page menu-item-129
                            nav-item
                          "
                        >
                          <router-link to="/active" class="nav-link">{{
                            $t('pages.header.subMenu_5a')
                          }}</router-link>
                        </li>
                      </ul>
                    </li>
                    <li
                      @click="slideLi"
                      @mouseover="mouserMenu"
                      @mouseout="mouserOut"
                      data-type="5"
                      :class="menu5"
                    >
                      <a href="javascript:;" class="nav-link">{{ $t('pages.header.menu_6') }}</a>
                      <ul class="sub-menu">
                        <li class="menu-item menu-item-type-post_type menu-item-object-page nav-item">
                          <a href="javascript:;" class="nav-link" @click="onSwitchLocale('en_US')">{{
                            $t('pages.header.subMenu_6a')
                          }}</a>
                        </li>
                        <li class="menu-item menu-item-type-post_type menu-item-object-page nav-item">
                          <a href="javascript:;" class="nav-link" @click="onSwitchLocale('zh_CN')">{{
                            $t('pages.header.subMenu_6b')
                          }}</a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- .fl-page-header -->
</template>

<script>
import util from '@/util';

export default {
  name: 'header',
  data() {
    return {
      menu1:
        'menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-109 nav-item',
      menu2:
        'menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-194 nav-item',
      menu3:
        'menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-133 nav-item',
      menu4:
        'menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-134 nav-item',
      menu5:
        'menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-135 nav-item',
      widthScroll: document.body.clientWidth,
      navClass: 'fl-page-nav-collapse collapse navbar-collapse',
      fixed:
        'fl-page-header fl-page-header-primary fl-page-nav-right fl-page-nav-toggle-button fl-page-nav-toggle-visible-mobile',
    };
  },

  mounted() {
    window.addEventListener('scroll', this.getScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.getScroll);
  },

  methods: {
    async onSwitchLocale(lang) {
      await util.locale.asyncLoadLocale(lang);
    },
    getScroll() {
      this.scrollTop =
        window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      if (!!document.documentElement.scrollTop && document.documentElement.scrollTop >= 200) {
        //设置滚动大于200时的执行内容 设置fixed
        this.fixed =
          'fl-page-header fl-page-header-primary fl-page-nav-right fl-page-nav-toggle-button fl-page-nav-toggle-visible-mobile fl-page-header-fixed';
      } else {
        //设置滚动小于200时的执行内容 取消设置fixed
        this.fixed =
          'fl-page-header fl-page-header-primary fl-page-nav-right fl-page-nav-toggle-button fl-page-nav-toggle-visible-mobile';
      }
    },

    jumpPage() {
      this.$router.push('helloworld');
    },

    mouserMenu(e) {
      let _type = e.currentTarget.dataset.type;

      if (this.widthScroll < 768) {
        return;
      }

      switch (_type) {
        case '1':
          this.menu1 = this.menu1 + ' fl-sub-menu-open';
          break;
        case '2':
          this.menu2 = this.menu2 + ' fl-sub-menu-open';
          break;
        case '3':
          this.menu3 = this.menu3 + ' fl-sub-menu-open';
          break;
        case '4':
          this.menu4 = this.menu4 + ' fl-sub-menu-open';
          break;
        case '5':
          this.menu5 = this.menu5 + ' fl-sub-menu-open';
          break;
      }
    },

    mouserOut(e) {
      let _type = e.currentTarget.dataset.type;

      if (this.widthScroll < 768) {
        return;
      }

      switch (_type) {
        case '1':
          this.menu1 = this.menu1.replace(' fl-sub-menu-open', '');
          break;
        case '2':
          this.menu2 = this.menu2.replace(' fl-sub-menu-open', '');
          break;
        case '3':
          this.menu3 = this.menu3.replace(' fl-sub-menu-open', '');
          break;
        case '4':
          this.menu4 = this.menu4.replace(' fl-sub-menu-open', '');
          break;
        case '5':
          this.menu5 = this.menu5.replace(' fl-sub-menu-open', '');
          break;
      }
    },

    openMenu() {
      if (this.navClass.indexOf(' in') != -1) {
        this.navClass = this.navClass.replace(' in', '');
      } else {
        this.navClass = this.navClass + ' in';
      }
    },

    slideLi(e) {
      let _type = e.currentTarget.dataset.type;

      if (this.widthScroll > 767) {
        return;
      }

      switch (_type) {
        case '1':
          if (this.menu1.indexOf(' fl-mobile-sub-menu-open') > 0) {
            this.menu1 = this.menu1.replace(' fl-mobile-sub-menu-open', '');
          } else {
            this.menu1 = this.menu1 + ' fl-mobile-sub-menu-open';
          }
          break;
        case '2':
          if (this.menu2.indexOf(' fl-mobile-sub-menu-open') > 0) {
            this.menu2 = this.menu1.replace(' fl-mobile-sub-menu-open', '');
          } else {
            this.menu2 = this.menu1 + ' fl-mobile-sub-menu-open';
          }
          break;
        case '3':
          if (this.menu3.indexOf(' fl-mobile-sub-menu-open') > 0) {
            this.menu3 = this.menu1.replace(' fl-mobile-sub-menu-open', '');
          } else {
            this.menu3 = this.menu1 + ' fl-mobile-sub-menu-open';
          }
          break;
        case '4':
          if (this.menu4.indexOf(' fl-mobile-sub-menu-open') > 0) {
            this.menu4 = this.menu1.replace(' fl-mobile-sub-menu-open', '');
          } else {
            this.menu4 = this.menu1 + ' fl-mobile-sub-menu-open';
          }
          break;
        case '5':
          if (this.menu5.indexOf(' fl-mobile-sub-menu-open') > 0) {
            this.menu5 = this.menu1.replace(' fl-mobile-sub-menu-open', '');
          } else {
            this.menu5 = this.menu1 + ' fl-mobile-sub-menu-open';
          }
          break;
      }
    },
  },
};
</script>

<style scoped>
.irlink-hkex-news-root {
  font-size: 17pt;
  position: relative;
  bottom: 30px;
}
.irlink-hkex-news-root a {
  color: #222;
}
.irlink-hkex-news-root .irlink-hkex-news-item:nth-child(odd) {
  background-color: transparent;
}
.irlink-hkex-news-root ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
.irlink-hkex-news-root .irlink-hkex-news-item {
  padding: 8px 8px;
  display: block;
  border-bottom: 2px #d4faf7 solid;
}
.irlink-hkex-news-root .irlink-hkex-news-item:hover {
  background-color: #70ad47;
}
.irlink-hkex-news-root a,
.irlink-hkex-news-root a:hover {
  text-decoration: none;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}
.irlink-hkex-news-root .arrow-left {
  border-top: 3px #222 solid;
  border-left: 3px #222 solid;
  background-color: transparent;
  height: 10px;
  width: 10px;
  transform: rotate(-45deg);
  display: inline-block;
  margin-left: 2px;
}
.irlink-hkex-news-root .arrow-right {
  border-top: 3px #222 solid;
  border-right: 3px #222 solid;
  background-color: transparent;
  height: 10px;
  width: 10px;
  transform: rotate(45deg);
  display: inline-block;
  margin-right: 2px;
}
.irlink-hkex-news-root .irlink-hkex-news-item .irlink-hkex-news-content {
  color: #000;
  font-size: smaller;
  display: flex;
  align-items: center;
}
/*.irlink-hkex-news-root .irlink-hkex-news-item .irlink-hkex-news-content a:hover {*/
/*  color: #2386EE;*/
/*}*/
.irlink-hkex-news-root .irlink-hkex-news-date {
  font-size: small;
  padding-top: 10px;
  padding-bottom: 15px;
  color: #000;
  text-align: left;
}
.irlink-hkex-news-root .irlink-hkex-news-link {
  margin-left: 10px;
}
.irlink-hkex-news-root .year-select {
  margin-bottom: 12px;
  text-align: right;
}
.irlink-hkex-news-root .left-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.irlink-hkex-news-root .select-box {
  display: inline-block;
  position: relative;
  margin: 0 0 0 8px;
  user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  z-index: 1;
}
.irlink-hkex-news-root .select-box .label {
  position: relative;
  display: inline-block;
  padding: 4px 50px 4px 30px;
  border: 2px solid #888;
  cursor: pointer;
  font-size: 20px;
}
.irlink-hkex-news-root .select-box .dropdown {
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  background-color: #fff;
  border: 1px solid #888;
}
.irlink-hkex-news-root .select-box .dropdown li {
  text-align: center;
  cursor: pointer;
}
.irlink-hkex-news-root .select-box .dropdown li a {
  display: block;
  padding: 4px 12px;
  font-size: 16px;
}
.irlink-hkex-news-root .select-box .dropdown li:hover {
  background-color: #70ad47;
}
.irlink-hkex-news-root .chevron-arrow-down {
  display: inline-block;
  border-right: 3px solid #888;
  border-bottom: 3px solid #888;
  width: 12px;
  height: 12px;
  transform: rotate(45deg);
  position: absolute;
  right: 16px;
  top: 50%;
  margin-top: -8px;
}
.irlink-hkex-news-root .paginator {
  text-align: center;
  padding-top: 20px;
}
.irlink-hkex-news-root .paginator li {
  display: inline-block;
  margin-right: 5px;
}
.irlink-hkex-news-root .paginator li a {
  display: inline-block;
  padding: 2px 4px;
  color: #222;
  font-size: 18px;
}
.irlink-hkex-news-root .paginator li.current a {
  display: inline-block;
  padding: 2px 4px;
  font-weight: bold;
}
.irlink-hkex-news-root .current-year {
  color: #70ad47;
  font-size: 30px;
}
.irlink-hkex-news-root .paging-button {
  position: relative;
  top: 1px;
  display: flex;
}
.irlink-hkex-news-root .first-last {
  width: 3px;
  height: 18px;
  background-color: #70ad47;
}
.irlink-hkex-news-root .left-triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 13.84px 8px 0;
  border-color: transparent #70ad47 transparent transparent;
}
.irlink-hkex-news-root .right-triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 0 8px 13.84px;
  border-color: transparent transparent transparent #70ad47;
}

.sub-menu li {
  text-align: left;
}

.fixed {
  position: fixed;
  top: 0;
  left: 0;
}
</style>
