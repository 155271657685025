<template>
  <div
    class="
      page-template-default page page-id-33
      fl-builder fl-framework-base fl-preset-default fl-full-width
    "
    itemscope="itemscope"
    itemtype="https://schema.org/WebPage"
  >
    <div class="fl-page">
      <v-header></v-header>
      <div class="fl-page-content" itemprop="mainContentOfPage">
        <div class="fl-content-full container">
          <div class="row">
            <div class="fl-content col-md-12">
              <article
                class="fl-post post-130 page type-page status-publish hentry"
                id="fl-post-130"
                itemscope="itemscope"
                itemtype="https://schema.org/CreativeWork"
              >
                <div class="fl-post-content clearfix" itemprop="text">
                  <div
                    class="
                      fl-builder-content
                      fl-builder-content-130
                      fl-builder-content-primary
                      fl-builder-global-templates-locked
                    "
                    data-post-id="130"
                  >
                    <div
                      class="
                        fl-row
                        fl-row-full-width
                        fl-row-bg-photo
                        fl-node-5da354ca49f92
                        fl-visible-desktop-medium
                      "
                      data-node="5da354ca49f92"
                    >
                      <div class="fl-row-content-wrap">
                        <div class="fl-row-content fl-row-fixed-width fl-node-content">
                          <div class="fl-col-group fl-node-5da354ca4d850" data-node="5da354ca4d850">
                            <div
                              class="fl-col fl-node-5da354ca4da2d fl-col-has-cols"
                              data-node="5da354ca4da2d"
                            >
                              <div class="fl-col-content fl-node-content">
                                <div
                                  class="fl-col-group fl-node-5da361f7ea075 fl-col-group-nested"
                                  data-node="5da361f7ea075"
                                >
                                  <div class="fl-col fl-node-5da361f7ea28b" data-node="5da361f7ea28b">
                                    <div class="fl-col-content fl-node-content">
                                      <div
                                        class="fl-module fl-module-rich-text fl-node-5da355407229c"
                                        data-node="5da355407229c"
                                      >
                                        <div class="fl-module-content fl-node-content">
                                          <div class="fl-rich-text">
                                            <p>&nbsp;</p>
                                            <p>&nbsp;</p>
                                            <p>&nbsp;</p>
                                            <p>&nbsp;</p>
                                            <p>&nbsp;</p>
                                            <p>&nbsp;</p>
                                            <p>&nbsp;</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="fl-row fl-row-fixed-width fl-row-bg-none fl-node-5db000cc704a8"
                      data-node="5db000cc704a8"
                    >
                      <div class="fl-row-content-wrap">
                        <div class="fl-row-content fl-row-fixed-width fl-node-content">
                          <div class="fl-col-group fl-node-5dca602f4c67b" data-node="5dca602f4c67b">
                            <div class="fl-col fl-node-5dca602f4c750" data-node="5dca602f4c750">
                              <div class="fl-col-content fl-node-content">
                                <div
                                  class="fl-module fl-module-rich-text fl-node-5dca602f4c5e6"
                                  data-node="5dca602f4c5e6"
                                >
                                  <div class="fl-module-content fl-node-content">
                                    <div class="fl-rich-text">
                                      <h1>
                                        {{ $t('pages.money.title') }}
                                      </h1>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="fl-col-group fl-node-5dc0eef093f10" data-node="5dc0eef093f10">
                            <div class="fl-col fl-node-5dc0eef09400d" data-node="5dc0eef09400d">
                              <div class="fl-col-content fl-node-content">
                                <div
                                  class="fl-module fl-module-rich-text fl-node-5dc0eef093e48"
                                  data-node="5dc0eef093e48"
                                >
                                  <div class="fl-module-content fl-node-content">
                                    <div class="fl-rich-text">
                                      <div>
                                        <div class="irlink-financial-data">
                                          <table>
                                            <tr>
                                              <td style="font-weight: bold; text-align: left">
                                                {{ $t('pages.money.currencyRMB') }}
                                              </td>
                                              <td style="font-weight: bold; text-align: right">
                                                {{ $t('pages.money.year1') }}
                                              </td>
                                              <td style="font-weight: bold; text-align: right">
                                                {{ $t('pages.money.year2') }}
                                              </td>
                                              <td style="font-weight: bold; text-align: right">
                                                {{ $t('pages.money.year3') }}
                                              </td>
                                              <td style="font-weight: bold; text-align: right">
                                                {{ $t('pages.money.year4') }}
                                              </td>
                                              <td style="font-weight: bold; text-align: right">
                                                {{ $t('pages.money.year5') }}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td style="text-align: left">
                                                {{ $t('pages.money.moneyInfo_1.title') }}
                                              </td>
                                              <td style="text-align: right">
                                                {{ $t('pages.money.moneyInfo_1.year1') }}
                                              </td>
                                              <td style="text-align: right">
                                                {{ $t('pages.money.moneyInfo_1.year2') }}
                                              </td>
                                              <td style="text-align: right">
                                                {{ $t('pages.money.moneyInfo_1.year3') }}
                                              </td>
                                              <td style="text-align: right">
                                                {{ $t('pages.money.moneyInfo_1.year4') }}
                                              </td>
                                              <td style="text-align: right">
                                                {{ $t('pages.money.moneyInfo_1.year5') }}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td style="text-align: left">
                                                {{ $t('pages.money.moneyInfo_2.title') }}
                                              </td>
                                              <td style="text-align: right">
                                                {{ $t('pages.money.moneyInfo_2.year1') }}
                                              </td>
                                              <td style="text-align: right">
                                                {{ $t('pages.money.moneyInfo_2.year2') }}
                                              </td>
                                              <td style="text-align: right">
                                                {{ $t('pages.money.moneyInfo_2.year3') }}
                                              </td>
                                              <td style="text-align: right">
                                                {{ $t('pages.money.moneyInfo_2.year4') }}
                                              </td>
                                              <td style="text-align: right">
                                                {{ $t('pages.money.moneyInfo_2.year5') }}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td style="text-align: left">
                                                {{ $t('pages.money.moneyInfo_3.title') }}
                                              </td>
                                              <td style="text-align: right">
                                                {{ $t('pages.money.moneyInfo_3.year1') }}
                                              </td>
                                              <td style="text-align: right">
                                                {{ $t('pages.money.moneyInfo_3.year2') }}
                                              </td>
                                              <td style="text-align: right">
                                                {{ $t('pages.money.moneyInfo_3.year3') }}
                                              </td>
                                              <td style="text-align: right">
                                                {{ $t('pages.money.moneyInfo_3.year4') }}
                                              </td>
                                              <td style="text-align: right">
                                                {{ $t('pages.money.moneyInfo_3.year5') }}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td style="text-align: left">
                                                {{ $t('pages.money.moneyInfo_4.title') }}
                                              </td>
                                              <td style="text-align: right">
                                                {{ $t('pages.money.moneyInfo_4.year1') }}
                                              </td>
                                              <td style="text-align: right">
                                                {{ $t('pages.money.moneyInfo_4.year2') }}
                                              </td>
                                              <td style="text-align: right">
                                                {{ $t('pages.money.moneyInfo_4.year3') }}
                                              </td>
                                              <td style="text-align: right">
                                                {{ $t('pages.money.moneyInfo_4.year4') }}
                                              </td>
                                              <td style="text-align: right">
                                                {{ $t('pages.money.moneyInfo_4.year5') }}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td style="text-align: left">
                                                {{ $t('pages.money.moneyInfo_5.title') }}
                                              </td>
                                              <td style="text-align: right">
                                                {{ $t('pages.money.moneyInfo_5.year1') }}
                                              </td>
                                              <td style="text-align: right">
                                                {{ $t('pages.money.moneyInfo_5.year2') }}
                                              </td>
                                              <td style="text-align: right">
                                                {{ $t('pages.money.moneyInfo_5.year3') }}
                                              </td>
                                              <td style="text-align: right">
                                                {{ $t('pages.money.moneyInfo_5.year4') }}
                                              </td>
                                              <td style="text-align: right">
                                                {{ $t('pages.money.moneyInfo_5.year5') }}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td style="text-align: left">
                                                {{ $t('pages.money.moneyInfo_6.title') }}
                                              </td>
                                              <td style="text-align: right">
                                                {{ $t('pages.money.moneyInfo_6.year1') }}
                                              </td>
                                              <td style="text-align: right">
                                                {{ $t('pages.money.moneyInfo_6.year2') }}
                                              </td>
                                              <td style="text-align: right">
                                                {{ $t('pages.money.moneyInfo_6.year3') }}
                                              </td>
                                              <td style="text-align: right">
                                                {{ $t('pages.money.moneyInfo_6.year4') }}
                                              </td>
                                              <td style="text-align: right">
                                                {{ $t('pages.money.moneyInfo_6.year5') }}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td style="text-align: left">
                                                {{ $t('pages.money.moneyInfo_7.title') }}
                                              </td>
                                              <td style="text-align: right">
                                                {{ $t('pages.money.moneyInfo_7.year1') }}
                                              </td>
                                              <td style="text-align: right">
                                                {{ $t('pages.money.moneyInfo_7.year2') }}
                                              </td>
                                              <td style="text-align: right">
                                                {{ $t('pages.money.moneyInfo_7.year3') }}
                                              </td>
                                              <td style="text-align: right">
                                                {{ $t('pages.money.moneyInfo_7.year4') }}
                                              </td>
                                              <td style="text-align: right">
                                                {{ $t('pages.money.moneyInfo_7.year5') }}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td style="text-align: left">
                                                {{ $t('pages.money.moneyInfo_8.title') }}
                                              </td>
                                              <td style="text-align: right">
                                                {{ $t('pages.money.moneyInfo_8.year1') }}
                                              </td>
                                              <td style="text-align: right">
                                                {{ $t('pages.money.moneyInfo_8.year2') }}
                                              </td>
                                              <td style="text-align: right">
                                                {{ $t('pages.money.moneyInfo_8.year3') }}
                                              </td>
                                              <td style="text-align: right">
                                                {{ $t('pages.money.moneyInfo_8.year4') }}
                                              </td>
                                              <td style="text-align: right">
                                                {{ $t('pages.money.moneyInfo_8.year5') }}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td style="text-align: left">
                                                {{ $t('pages.money.moneyInfo_9.title') }}
                                              </td>
                                              <td style="text-align: right">
                                                {{ $t('pages.money.moneyInfo_9.year1') }}
                                              </td>
                                              <td style="text-align: right">
                                                {{ $t('pages.money.moneyInfo_9.year2') }}
                                              </td>
                                              <td style="text-align: right">
                                                {{ $t('pages.money.moneyInfo_9.year3') }}
                                              </td>
                                              <td style="text-align: right">
                                                {{ $t('pages.money.moneyInfo_9.year4') }}
                                              </td>
                                              <td style="text-align: right">
                                                {{ $t('pages.money.moneyInfo_9.year5') }}
                                              </td>
                                            </tr>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
      <v-footer></v-footer>
    </div>
  </div>
</template>

<script>
import vFooter from '../components/footer';
import vHeader from '../components/header';
export default {
  name: 'first',
  components: {
    vFooter,
    vHeader,
  },

  data() {
    return {};
  },

  methods: {},
};
</script>

<style scoped>
.irlink-financial-data table {
  width: 100%;
  margin: 0 auto;
}
.irlink-financial-data table tr td {
  border: none;
  padding: 2.2% 1%;
  color: #000;
  max-width: 120px;
}
.irlink-financial-data table tr td:not(:first-child) {
  max-width: 70px;
}
.irlink-financial-data table tr:first-child td {
  border-top: 1px #70ad47 solid;
  border-bottom: 1px #70ad47 solid;
}
.irlink-financial-data table tr:nth-child(odd) td {
  background-color: #fff;
}
.irlink-financial-data table tr:nth-child(even) td {
  background-color: #e2efdb;
}
@media only screen and (max-width: 480px) {
  .irlink-financial-data table tr td {
    font-size: 6px;
  }
}
</style>
