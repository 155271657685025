import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from '@/locale';
import './assets/css/base.min.css';
import './assets/css/irlink-wp-public.css';
import './assets/css/jquery.magnificpopup.min.css';
import './assets/css/skin-5de4bc8aa84cc.css';
import './assets/css/tablepress-combined.min.css';
import './assets/css/theme.min.css';
import './assets/css/style.min.css';
import './assets/css/5-layout.css';
import './assets/css/33-layout.css';
import './assets/css/79-layout.css';
import './assets/css/97-layout.css';
import './assets/css/100-layout.css';
import './assets/css/111-layout.css';
import './assets/css/130-layout.css';
import './assets/css/118-layout.css';
import './assets/css/119-layout.css';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
