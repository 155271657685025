<template>
  <div
    class="
      page-template-default page page-id-33
      fl-builder fl-framework-base fl-preset-default fl-full-width
    "
    itemscope="itemscope"
    itemtype="https://schema.org/WebPage"
  >
    <div class="fl-page">
      <v-header></v-header>
      <div class="fl-page-content" itemprop="mainContentOfPage">
        <div class="fl-content-full container">
          <div class="row">
            <div class="fl-content col-md-12">
              <article
                class="fl-post post-114 page type-page status-publish hentry"
                id="fl-post-114"
                itemscope="itemscope"
                itemtype="https://schema.org/CreativeWork"
              >
                <div class="fl-post-content clearfix" itemprop="text">
                  <div
                    class="
                      fl-builder-content
                      fl-builder-content-114
                      fl-builder-content-primary
                      fl-builder-global-templates-locked
                    "
                    data-post-id="114"
                  >
                    <div
                      class="
                        fl-row
                        fl-row-full-width
                        fl-row-bg-photo
                        fl-node-5da354ca49f92
                        fl-visible-desktop-medium
                      "
                      data-node="5da354ca49f92"
                    >
                      <div class="fl-row-content-wrap">
                        <div class="fl-row-content fl-row-fixed-width fl-node-content">
                          <div class="fl-col-group fl-node-5da354ca4d850" data-node="5da354ca4d850">
                            <div
                              class="fl-col fl-node-5da354ca4da2d fl-col-has-cols"
                              data-node="5da354ca4da2d"
                            >
                              <div class="fl-col-content fl-node-content">
                                <div
                                  class="fl-col-group fl-node-5da361f7ea075 fl-col-group-nested"
                                  data-node="5da361f7ea075"
                                >
                                  <div class="fl-col fl-node-5da361f7ea28b" data-node="5da361f7ea28b">
                                    <div class="fl-col-content fl-node-content">
                                      <div
                                        class="fl-module fl-module-rich-text fl-node-5da355407229c"
                                        data-node="5da355407229c"
                                      >
                                        <div class="fl-module-content fl-node-content">
                                          <div class="fl-rich-text">
                                            <p>&nbsp;</p>
                                            <p>&nbsp;</p>
                                            <p>&nbsp;</p>
                                            <p>&nbsp;</p>
                                            <p>&nbsp;</p>
                                            <p>&nbsp;</p>
                                            <p>&nbsp;</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="fl-row fl-row-fixed-width fl-row-bg-none fl-node-5db0011a1d1b5"
                      data-node="5db0011a1d1b5"
                    >
                      <div class="fl-row-content-wrap">
                        <div class="fl-row-content fl-row-fixed-width fl-node-content">
                          <div class="fl-col-group fl-node-5dca60f0202f9" data-node="5dca60f0202f9">
                            <div class="fl-col fl-node-5dca60f0203b3" data-node="5dca60f0203b3">
                              <div class="fl-col-content fl-node-content">
                                <div
                                  class="fl-module fl-module-rich-text fl-node-5dca60f020272"
                                  data-node="5dca60f020272"
                                >
                                  <div class="fl-module-content fl-node-content">
                                    <div class="fl-rich-text">
                                      <h1>
                                        {{ $t('pages.active.title') }}
                                      </h1>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="fl-col-group fl-node-5db6783d2ca99" data-node="5db6783d2ca99">
                            <div class="fl-col fl-node-5db6783d2cbb4" data-node="5db6783d2cbb4">
                              <div class="fl-col-content fl-node-content">
                                <div
                                  class="fl-module fl-module-rich-text fl-node-5db6783d2c9c4"
                                  data-node="5db6783d2c9c4"
                                >
                                  <div class="fl-module-content fl-node-content">
                                    <div class="fl-rich-text">
                                      <div>
                                        <div class="irlink-blog-posts2">
                                          <div class="year-select left-right">
                                            <div class="left current-year">
                                              {{ currentYear }}
                                            </div>
                                            <div class="right">
                                              <div class="select-box">
                                                <div class="label" @click="dropdownList">
                                                  {{ currentYear
                                                  }}<span class="chevron-arrow-down"></span>
                                                </div>
                                                <ul :class="['dropdown', dropdownBlock]">
                                                  <li>
                                                    <a
                                                      @click="change"
                                                      href="javascript:void(0)"
                                                      data-year="2021"
                                                      >2021</a
                                                    >
                                                  </li>
                                                  <li>
                                                    <a
                                                      @click="change"
                                                      href="javascript:void(0)"
                                                      data-year="2020"
                                                      >2020</a
                                                    >
                                                  </li>
                                                  <li>
                                                    <a
                                                      @click="change"
                                                      href="javascript:void(0)"
                                                      data-year="2019"
                                                      >2019</a
                                                    >
                                                  </li>
                                                  <li>
                                                    <a
                                                      @click="change"
                                                      href="javascript:void(0)"
                                                      data-year="2018"
                                                      >2018</a
                                                    >
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                          </div>
                                          <block v-if="currentYear == 2021">
                                            <!-- 2021 - 1 -->
                                            <div class="posts-container" v-if="currentPage == 1">
                                              <ul class="posts">
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/60bef390891d0e1c132d326b"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2021.newsDate_1') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/1RkCjcT5quQzj_YDuKRlHA"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2021.newsTitle_1') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/60bef3ec891d0e1c132d32a2"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2021.newsDate_2') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/TNs3AqxZz3ZMh4TnklLbRQ"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2021.newsTitle_2') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/60bef41d891d0e1c132d32b2"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2021.newsDate_3') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/bi9MOugunca_qg2Xjp6yxg"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2021.newsTitle_3') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/60bef328891d0e1c132d323f"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2021.newsDate_4') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/id5i-h5zRyXY3bMwONep2A"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2021.newsTitle_4') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/60bef358be2bce1bf710d9e9"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2021.newsDate_5') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/yxk5qk1cBEBFz_ana5rZKQ"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2021.newsTitle_5') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/60bef270891d0e1c132d31ec"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2021.newsDate_6') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/mn4ZdYfJ7849gKQwWO1g1w"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2021.newsTitle_6') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>

                                            <!-- 2021-2 -->
                                            <div class="posts-container" v-if="currentPage == 2">
                                              <ul class="posts">
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/60bef0f2424b7a1bf013f763"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2021.newsDate_7') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/b_vFqGGyZ7YX6b6i6RsG6Q"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2021.newsTitle_7') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/60bef171891d0e1c132d3144"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2021.newsDate_8') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/m7Yx_EoVzjanmTExWt8obA"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2021.newsTitle_8') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/60bef1e6891d0e1c132d3181"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2021.newsDate_9') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/quNpk9H-Zm8Th-Jz4Apaww"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2021.newsTitle_9') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/60bef22d45155b1c1a13f0da"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2021.newsDate_10') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/0XxmChxk-RYqP_uG2mVhMA"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2021.newsTitle_10') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/60beef8545155b1c1a13efce"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2021.newsDate_11') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/0qF7Y27vG6jeN4fBxK1SyA"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2021.newsTitle_11') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/60bef03d45155b1c1a13f026"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2021.newsDate_12') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/PXSBxK4x9z6OMGs_ZmJ0SQ"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2021.newsTitle_12') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                            <!-- 2021-3 -->
                                            <div class="posts-container" v-if="currentPage == 3">
                                              <ul class="posts">
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/60bef09e45155b1c1a13f03f"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2021.newsDate_13') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/laVK_KKRVBtl0x3ywDWGIA"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2021.newsTitle_13') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/60beee78891d0e1c132d3002"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2021.newsDate_14') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/5AtOnBTy706NdOwE3H87aw"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2021.newsTitle_14') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/60beeef2424b7a1bf013f6b7"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2021.newsDate_15') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/tdq33RgwCGsDaa33JHAJpA"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2021.newsTitle_15') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/60beef5245155b1c1a13efb7"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2021.newsDate_16') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/N7V0uLB7Cc70exUnuz8_kw"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2021.newsTitle_16') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/604628ff4ce08a7ac3d1d465"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2021.newsDate_17') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/h6cUOpc7NYuQoPbt7VRsxw"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2021.newsTitle_17') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/604628c268e9267b010b1f79"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2021.newsDate_18') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/end2gM3TPpqOoC6WiaQCTw"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2021.newsTitle_18') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>

                                            <!-- 2021-4 -->
                                            <div class="posts-container" v-if="currentPage == 4">
                                              <ul class="posts">
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/604627b556c6d37aeaca3167"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2021.newsDate_19') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/SRVq-Hsx4WQE7A1ZI0noDA"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2021.newsTitle_19') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/6046285568e9267b010b1f66"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2021.newsDate_20') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/Uk1gqFUu_y50Xhk1bBtVNg"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2021.newsTitle_20') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/6046273e4ce08a7ac3d1d402"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2021.newsDate_21') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/U4R4Jh8OrJMK7p8kfYbGQw"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2021.newsTitle_21') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/604626f82909e67aca578655"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2021.newsDate_22') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/FL5zfEGxQfsRVbS3Tdfg4w"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2021.newsTitle_22') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/604625a156c6d37aeaca2fd8"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2021.newsDate_23') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/q0dLHi-aVS3TTOhXOyRBRg"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2021.newsTitle_23') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/604626184ce08a7ac3d1d328"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2021.newsDate_24') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/vJC2MkmgHT6s920YKg60wg"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2021.newsTitle_24') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                            <!-- 2021-5 -->
                                            <div class="posts-container" v-if="currentPage == 5">
                                              <ul class="posts">
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/6046268b2909e67aca57859c"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2021.newsDate_25') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/EwGzQmLAHsrxhsLql72xcA"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2021.newsTitle_25') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/6046254c2909e67aca578524"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2021.newsDate_26') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/8nGIuoQQywu3tY6fXmsFCg"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2021.newsTitle_26') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/6013d31268e9267b0101331b"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2021.newsDate_27') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/5DnJ30hfb5K1EOzIi520yg"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2021.newsTitle_27') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/6013ccb556c6d37aeac03304"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2021.newsDate_28') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/NTQSnthkBPVFaV58XI5_cQ"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2021.newsTitle_28') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/6013cc3a2909e67aca4d8483"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2021.newsDate_29') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/b-M_QlVbngRsyToF_q4GYA"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2021.newsTitle_29') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </block>

                                          <block v-if="currentYear == 2020">
                                            <!-- 2020-1 -->
                                            <div class="posts-container" v-if="currentPage == 1">
                                              <ul class="posts">
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/5fd99c12cd4cfc338e02a7b8"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2020.newsDate_1') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/5RtOWsu8TUIWSgCJWTyxbQ"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2020.newsTitle_1') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/5fd9a6bb1da32033b9ee616e"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2020.newsDate_2') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/1qHqn3JTna3SJAgpNSsmPA"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2020.newsTitle_2') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/5fd99e14fbc8763395a30660"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2020.newsDate_3') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/EImugrhl_HPwNbeLulPV-g"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2020.newsTitle_3') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/5fd99df81da32033b9ee5fc7"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2020.newsDate_4') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/GzE27K-skur1PwaDcrtz0A"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2020.newsTitle_4') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/5fd99e84aa831033b2e5ee8d"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2020.newsDate_5') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/en9UFg3Roqt8IPsBYTyhtQ"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2020.newsTitle_5') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/5fd99ee81da32033b9ee5ff6"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2020.newsDate_6') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/vwB1vtoRUtfjHHw9GVPv-A"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2020.newsTitle_6') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>

                                            <!-- 2020-2 -->
                                            <div class="posts-container" v-if="currentPage == 2">
                                              <ul class="posts">
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/5fd9a7d1aa831033b2e5f01a"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2020.newsDate_7') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/GYjV4eL13q8OR9PhAo0Ygw"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2020.newsTitle_7') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/5fd9ae18aa831033b2e5f129"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2020.newsDate_8') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/6yA0UvU4f4G_gWZYa4r1wg"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2020.newsTitle_8') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/5fd9a88efbc8763395a30823"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2020.newsDate_9') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/uAASkL9_223NYS6_yl4lrw"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2020.newsTitle_9') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/5fd9a8b2cd4cfc338e02a9bf"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2020.newsDate_10') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/Hw1mHCoz4fz3CWM5GeJdWw"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2020.newsTitle_10') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/5fd9b0d11da32033b9ee6372"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2020.newsDate_11') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/1T5h6VLG5nuNRc848N9EIw"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2020.newsTitle_11') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/5fd9b1291da32033b9ee639a"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2020.newsDate_12') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/GeBFznRoh8SEZ6xKoOCQUQ"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2020.newsTitle_12') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                            <!-- 2020-3 -->
                                            <div class="posts-container" v-if="currentPage == 3">
                                              <ul class="posts">
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/5fd9b1ddaa831033b2e5f1db"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2020.newsDate_13') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/qS3_iFF3XzsGUiQXcP6QIA"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2020.newsTitle_13') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/5fd9b250cd4cfc338e02ab58"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2020.newsDate_14') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/deiPdRFRcixTuhHi9O2tvQ"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2020.newsTitle_14') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/5fd9e158aa831033b2e60a13"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2020.newsDate_15') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/jidpM5fXczs7daQYTtA9BQ"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2020.newsTitle_15') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/5fd9e06aaa831033b2e609c2"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2020.newsDate_16') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/C6XamzXcZpvW8N8Zd0MwYg"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2020.newsTitle_16') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/5fd9e210aa831033b2e60ac8"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2020.newsDate_17') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/Fpt_TIpbTiwCmloWSnwXnw"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2020.newsTitle_17') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/5fd9e28daa831033b2e60b7d"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2020.newsDate_18') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/TbeJvnhZTAZMT_NXKefQeA"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2020.newsTitle_18') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>

                                            <!-- 2020-4 -->
                                            <div class="posts-container" v-if="currentPage == 4">
                                              <ul class="posts">
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/5fd9e2ecaa831033b2e60b90"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2020.newsDate_19') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/u9CcRV9fwsnpgR7Hh0cDJA"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2020.newsTitle_19') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/5fd9e374fbc8763395a31b69"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2020.newsDate_20') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/Z2r-28eqKD_wn6dttewPeg"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2020.newsTitle_20') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/5fd9e445fbc8763395a31bc0"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2020.newsDate_21') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/Ze55NYxT2z5PlJdpawfmGQ"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2020.newsTitle_21') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/5fd9e4dbfbc8763395a31bdb"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2020.newsDate_22') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/puorzzCCq4JnTb3So6MKyA"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2020.newsTitle_22') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/5fd9e5591da32033b9ee73e3"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2020.newsDate_23') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/xak0oq2ZsTr10h37TQxWGQ"
                                                        target="_blank"
                                                      >
                                                        &quot;{{
                                                          $t('pages.active.news2020.newsTitle_23a')
                                                        }}&quot;
                                                        {{ $t('pages.active.news2020.newsTitle_23b') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/5fd9e5accd4cfc338e02bc11"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2020.newsDate_24') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/IcvHfUfNTVBpX1gG7r0bIw"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2020.newsTitle_24') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>

                                            <!-- 2020-5 -->
                                            <div class="posts-container" v-if="currentPage == 5">
                                              <ul class="posts">
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/5fd9e66aaa831033b2e60c69"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2020.newsDate_25') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/pyZdzOH7_lktJJ-maRKIJA "
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2020.newsTitle_25') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/5fd9e69afbc8763395a31c4e"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2020.newsDate_26') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/v51wF01bdGJhHEkYJ3AAyg"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2020.newsTitle_26') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/5fd9e6e7aa831033b2e60c96"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2020.newsDate_27') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/7826j0i8GfCoBZA2Zmmgkw"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2020.newsTitle_27') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/5fdae59c1da32033b9ee99f4"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2020.newsDate_28') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/VfacGmeneTRMtqtDHoI7VQ"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2020.newsTitle_28') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/5fdae7f51da32033b9ee9a76"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2020.newsDate_29') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/uCH_vQlBkEqdFvPsRc_D7w"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2020.newsTitle_29') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/5fdae860fbc8763395a3402c"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2020.newsDate_30') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/DQvhpzlIF7qetWvIIYvXsg"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2020.newsTitle_30') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>

                                            <!-- 2020-6 -->
                                            <div class="posts-container" v-if="currentPage == 6">
                                              <ul class="posts">
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/5fdae90baa831033b2e6317d"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2020.newsDate_31') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/uCH_vQlBkEqdFvPsRc_D7w"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2020.newsTitle_31') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/5fdae9491da32033b9ee9ae6"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2020.newsDate_32') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/FYfAtvbkGiCseBpMWHTDMQ"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2020.newsTitle_32') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/5fdae9771da32033b9ee9af7"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2020.newsDate_33') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/_hWLuYhV6o3vjs6LhmQuIQ "
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2020.newsTitle_33') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/5fdae9bc1da32033b9ee9b1a"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2020.newsDate_34') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/-5sMYp83pLMvS6vNpK6Zcg"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2020.newsTitle_34') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/5fdae9f1aa831033b2e631d4"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2020.newsDate_35') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/YtsYFoKAwfpF-EtY5QDd4A"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2020.newsTitle_35') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/5fdaeaf3aa831033b2e63216"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2020.newsDate_36') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/EvgWeagf51oCGw5fp0_z1Q"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2020.newsTitle_36') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>

                                            <!-- 2020-7 -->
                                            <div class="posts-container" v-if="currentPage == 7">
                                              <ul class="posts">
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/5fdaeb571da32033b9ee9b5c"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2020.newsDate_37') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/OeyPPjebNUs27I-VHr2GYw"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2020.newsTitle_37') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/5fdaeb7efbc8763395a340b6"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2020.newsDate_38') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/qh24_SxlvHETdUCUcDZuvw"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2020.newsTitle_38') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/5fdaebe3fbc8763395a34111"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2020.newsDate_39') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/0pMGvSjmLEJg8IZoW1Y8XQ"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2020.newsTitle_39') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/5fdb62eaaa831033b2e65338"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2020.newsDate_40') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/_RAQSAkCrSAXvL-_uNZTyA"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2020.newsTitle_40') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/5fdb6343aa831033b2e6534e"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2020.newsDate_41') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/kpVr-TIKIzXY09vawJ4mMA"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2020.newsTitle_41') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/5fdb6389cd4cfc338e0303c0"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2020.newsDate_42') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/ONHbIFSUGmi8ItZM36FPng"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2020.newsTitle_42') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                            <!-- 2020-8 -->
                                            <div class="posts-container" v-if="currentPage == 8">
                                              <ul class="posts">
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/5fdb63fdfbc8763395a360f9"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2020.newsDate_43') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/qkx_VGu7ivIOluKUeSdYKQ"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2020.newsTitle_43') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/5fdb6448aa831033b2e65389"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2020.newsDate_44') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/ETD0fMmykGTXLSdQfvoOGA"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2020.newsTitle_44') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/5fdb648dfbc8763395a3613f"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2020.newsDate_45') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/PVy3b2DgLl9FIINsUv6jJA"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2020.newsTitle_45') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/5fdb6640cd4cfc338e030463"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2020.newsDate_46') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/bA231MB2Cu6gGyAoS3G1nw"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2020.newsTitle_46') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/5fdb6697aa831033b2e6544c"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2020.newsDate_47') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/oqaEJ3DvEIWiZGlw3-Cogg"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2020.newsTitle_47') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/5fdb66dcaa831033b2e65462"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2020.newsDate_48') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/jv79f4Bn4H1_IoHX7CVFVw"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2020.newsTitle_48') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>

                                            <!-- 2020-9 -->
                                            <div class="posts-container" v-if="currentPage == 9">
                                              <ul class="posts">
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/5fdb6728aa831033b2e65471"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2020.newsDate_49') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/6i7E2yMRFsdfaqumV8zCzA"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2020.newsTitle_49') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/5fdb67b0cd4cfc338e0304a7"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2020.newsDate_50') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/PiAJi5fClNrRhvwdBzSbCg"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2020.newsTitle_50') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/5fdb67eacd4cfc338e0304bb"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2020.newsDate_51') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/Zcsbn0bmUkYuDxMFPkOvnw"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2020.newsTitle_51') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/5fdb68c5cd4cfc338e0304da"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2020.newsDate_52') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/Yuz0jDfp8t5T-pDIxaEgfg"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2020.newsTitle_52') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/5fdb691efbc8763395a3622e"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2020.newsDate_53') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/NvtUaOgJyWaiCP22kbECmw"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2020.newsTitle_53') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/5fdb6972aa831033b2e654d5"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2020.newsDate_54') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/Jtk__qhs4WkTFkz2TIwQNQ"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2020.newsTitle_54') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>

                                            <!-- 2020-10 -->
                                            <div class="posts-container" v-if="currentPage == 10">
                                              <ul class="posts">
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/5fdb69e01da32033b9eec949"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2020.newsDate_55') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/QML_4diRnIsAPBHeyMxNfw"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2020.newsTitle_55') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/5fdb6a1ecd4cfc338e030519"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2020.newsDate_56') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/IyogK8hXCo_gKT3HKo3eag"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2020.newsTitle_56') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/5fdb6a5daa831033b2e65522"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2020.newsDate_57') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/c7GKE4KFM2Hx9ZZ84IOIoQ"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2020.newsTitle_57') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/5fdb6ac0fbc8763395a3628c"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2020.newsDate_58') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/lezX4UZbhwUu4hPGu5JLAQ"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2020.newsTitle_58') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/5fdb6af0fbc8763395a362ae"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2020.newsDate_59') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/WeMmX2T4KtmJQm-nZCQqfg"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2020.newsTitle_59') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </block>
                                          <block v-if="currentYear == 2019">
                                            <!-- 2019-1 -->
                                            <div class="posts-container" v-if="currentPage == 1">
                                              <ul class="posts">
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/5fdb6c5dfbc8763395a36325"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2019.newsDate_1') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/O_OUAD68i0erDavZwfX1zQ"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2019.newsTitle_1') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/5fdb6b78fbc8763395a362c9"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2019.newsDate_2') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/tMOOaPJEOl4vqO4RNlR4kw"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2019.newsTitle_2') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/5fd99b4aaa831033b2e5edfe"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2019.newsDate_3') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/Bq9S3AArstQ90BXId4o0gA"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2019.newsTitle_3') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/5fd98fe9cd4cfc338e02a54f"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2019.newsDate_4') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/3z_IuQ5Sq-9BIBdnWG-VtQ"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2019.newsTitle_4') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/5fd794781da32033b9edff6e"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2019.newsDate_5') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s/G2bU27LPS9EN-Yvn5J4ySQ"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2019.newsTitle_5') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/5db7bdb65769d72ae285a5b5"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2019.newsDate_6') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s?__biz&#x3D;MzI3MjExNjQ4OQ&#x3D;&#x3D;&amp;mid&#x3D;2649886824&amp;idx&#x3D;1&amp;sn&#x3D;1a872d9fa56106fc6072aa5c5746cc6e&amp;chksm&#x3D;f331ce0ec4464718276d08ffaddde9f435482a333132672e6d51fea72ccb5de012d913f8c9ba&amp;mpshare&#x3D;1&amp;scene&#x3D;1&amp;srcid&#x3D;1004A3jRhbboxZNfZ4NWO9Ry&amp;sharer_sharetime&#x3D;1570161186374&amp;sharer_shareid&#x3D;3463421222946fc485976b13826e9903&amp;key&#x3D;5a37b8e9f293346331b56dfbd5214351d4cc325d44a46036b71de7bd0d10661ff43dbf877b6a26c6407c64ff73f4d676e3d4723bcc26100c321220b9f1ee7851b323656858bf2aa359ec2f40a9bc1ca9&amp;ascene&#x3D;1&amp;uin&#x3D;MjQwODg0NTcwMw%3D%3D&amp;devicetype&#x3D;Windows+10&amp;version&#x3D;62070141&amp;lang&#x3D;zh_CN&amp;pass_ticket&#x3D;nyYDKj4I3yeeGhmjqAetoeoyB7Cl9wuwBsfQ0crSNd0YDaJ2REcnMBJwCYsgcwy2"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2019.newsTitle_6') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                            <!-- 2019-2 -->
                                            <div class="posts-container" v-if="currentPage == 2">
                                              <ul class="posts">
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/5db7be095769d72ae285a5cf"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2019.newsDate_7') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s?__biz&#x3D;MzI3MjExNjQ4OQ&#x3D;&#x3D;&amp;mid&#x3D;2649886824&amp;idx&#x3D;2&amp;sn&#x3D;0f2d81662ac81210b5c2fef197a86549&amp;chksm&#x3D;f331ce0ec4464718f5b8865c5c61a74b61092647895bf0aa9fdcb5213cdf9c621be7e5e9733d&amp;mpshare&#x3D;1&amp;scene&#x3D;1&amp;srcid&#x3D;&amp;sharer_sharetime&#x3D;1570161192287&amp;sharer_shareid&#x3D;3463421222946fc485976b13826e9903&amp;key&#x3D;69f28381a3e17e70ba03c0e7df13d9c00b756d8a1d45ff8738f2259cd9f73ee7a019ef66d8c561a214f971f13a78e7b8a28fe4d6d5040b57f23700c20c6a75922c968b51a56727e39d13c6375693aee5&amp;ascene&#x3D;1&amp;uin&#x3D;MjQwODg0NTcwMw%3D%3D&amp;devicetype&#x3D;Windows+10&amp;version&#x3D;62070141&amp;lang&#x3D;zh_CN&amp;pass_ticket&#x3D;nyYDKj4I3yeeGhmjqAetoeoyB7Cl9wuwBsfQ0crSNd0YDaJ2REcnMBJwCYsgcwy2"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2019.newsTitle_7') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/5db7bcc95769d72ae285a56e"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2019.newsDate_8') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s?__biz&#x3D;MzI3MjExNjQ4OQ&#x3D;&#x3D;&amp;mid&#x3D;2649886744&amp;idx&#x3D;3&amp;sn&#x3D;16c6482ba34a753da4e4f96ee3a716b9&amp;chksm&#x3D;f331ce7ec4464768b23289b5ad28513d615bec0261c604f1105cf7f027e59f32c48e16a06e64&amp;mpshare&#x3D;1&amp;scene&#x3D;1&amp;srcid&#x3D;&amp;sharer_sharetime&#x3D;1570161207267&amp;sharer_shareid&#x3D;3463421222946fc485976b13826e9903&amp;key&#x3D;b1719993cc296ec475d349d37b3adc9b58c9e4d033562ea7d4d4c9af7b6aba236d454a89e4b3c9fbd5923e8ca20499b9e7d1d693704815902400ad177b3c21a1739fb7e83f7af22b14ef152c12f86458&amp;ascene&#x3D;1&amp;uin&#x3D;MjQwODg0NTcwMw%3D%3D&amp;devicetype&#x3D;Windows+10&amp;version&#x3D;62070141&amp;lang&#x3D;zh_CN&amp;pass_ticket&#x3D;nyYDKj4I3yeeGhmjqAetoeoyB7Cl9wuwBsfQ0crSNd0YDaJ2REcnMBJwCYsgcwy2"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2019.newsTitle_8') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/5db7bd6e9a23792ab447fdd1"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2019.newsDate_9') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s?__biz&#x3D;MzI3MjExNjQ4OQ&#x3D;&#x3D;&amp;mid&#x3D;2649886744&amp;idx&#x3D;1&amp;sn&#x3D;3152ef25b208586182e0570de9828b77&amp;chksm&#x3D;f331ce7ec4464768d602f490349bfb49fcb81c2b368f3a02578b796b55f72ec98db1cb579e28&amp;mpshare&#x3D;1&amp;scene&#x3D;1&amp;srcid&#x3D;&amp;sharer_sharetime&#x3D;1570161198939&amp;sharer_shareid&#x3D;3463421222946fc485976b13826e9903&amp;key&#x3D;b1719993cc296ec4a28864825ecc3ccee69b50c339ea46fa7d132187368f55c32f9dad3892eb14e18b6b7b90f2420db95edca489bfbcc4645d03bda0fbe40fe2c3594d21f47d58d6ed2d6bd4f003ecb3&amp;ascene&#x3D;1&amp;uin&#x3D;MjQwODg0NTcwMw%3D%3D&amp;devicetype&#x3D;Windows+10&amp;version&#x3D;62070141&amp;lang&#x3D;zh_CN&amp;pass_ticket&#x3D;nyYDKj4I3yeeGhmjqAetoeoyB7Cl9wuwBsfQ0crSNd0YDaJ2REcnMBJwCYsgcwy2"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2019.newsTitle_9') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/5db7d6c87c93ac3ba523bb87"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2019.newsDate_10') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s?__biz&#x3D;MzI3MjExNjQ4OQ&#x3D;&#x3D;&amp;mid&#x3D;2649886710&amp;idx&#x3D;1&amp;sn&#x3D;2e3535c5e05830c8a8e68e459953c580&amp;chksm&#x3D;f331d190c44658866c567d6fde4a2a049204b6cd4c73134bd849fc6db4885edb63cf6272ebe5&amp;mpshare&#x3D;1&amp;scene&#x3D;1&amp;srcid&#x3D;&amp;sharer_sharetime&#x3D;1570161176336&amp;sharer_shareid&#x3D;3463421222946fc485976b13826e9903&amp;key&#x3D;69f28381a3e17e70f7fdd4256b60551184a9d0bf4160ef58093189e906db3e8a8fe0882ff99bb9522ebd720080a303453556d45543df3c72dd253bedfff4c6c079bedbd8328d8cec66119434df479756&amp;ascene&#x3D;1&amp;uin&#x3D;MjQwODg0NTcwMw%3D%3D&amp;devicetype&#x3D;Windows+10&amp;version&#x3D;62070141&amp;lang&#x3D;zh_CN&amp;pass_ticket&#x3D;nyYDKj4I3yeeGhmjqAetoeoyB7Cl9wuwBsfQ0crSNd0YDaJ2REcnMBJwCYsgcwy2"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2019.newsTitle_10') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/5db7bc579a23792ab447fd9a"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2019.newsDate_11') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s?__biz&#x3D;MzI3MjExNjQ4OQ&#x3D;&#x3D;&amp;mid&#x3D;2649886710&amp;idx&#x3D;3&amp;sn&#x3D;0aa022f67c4198fa364bea716bb1f38c&amp;chksm&#x3D;f331d190c4465886e021448026971823fdcd1644c7711573e2233abdc304cccc84ee022b0b33&amp;mpshare&#x3D;1&amp;scene&#x3D;1&amp;srcid&#x3D;&amp;sharer_sharetime&#x3D;1570161215604&amp;sharer_shareid&#x3D;3463421222946fc485976b13826e9903&amp;key&#x3D;00a5eb0b7d9050388f711141dc1bfa16a73d5082ebef90cdd53335f4e3657aef6ec96ff6af34440568e6f94f73e9f7d5e790f856151890732531f0c07b8c2f547d0e2aec5d6529434cf1b8763bdc36ec&amp;ascene&#x3D;1&amp;uin&#x3D;MjQwODg0NTcwMw%3D%3D&amp;devicetype&#x3D;Windows+10&amp;version&#x3D;62070141&amp;lang&#x3D;zh_CN&amp;pass_ticket&#x3D;nyYDKj4I3yeeGhmjqAetoeoyB7Cl9wuwBsfQ0crSNd0YDaJ2REcnMBJwCYsgcwy2"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2019.newsTitle_11') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/5db7ba69f60bcc2af43bd9a1"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2019.newsDate_12') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s?__biz&#x3D;MzI3MjExNjQ4OQ&#x3D;&#x3D;&amp;mid&#x3D;2649886701&amp;idx&#x3D;4&amp;sn&#x3D;280acb3ece6720c897a2fd8cbcff6cfd&amp;chksm&#x3D;f331d18bc446589d4acd631c96999abc27d153f108c52894a28efde3c1b525df5ec025be9d0b&amp;mpshare&#x3D;1&amp;scene&#x3D;1&amp;srcid&#x3D;&amp;sharer_sharetime&#x3D;1570161221556&amp;sharer_shareid&#x3D;3463421222946fc485976b13826e9903&amp;key&#x3D;5a37b8e9f2933463ecfa700de5c5791693b5bb02d94f7e7ff230b871ca3b4eda1509f1a339d126b4fc9c3f17d59bf15d9aaa4b493ef1f169efff27ecc87f926c7a2775bec9555b3a20f19d8f9b444a45&amp;ascene&#x3D;1&amp;uin&#x3D;MjQwODg0NTcwMw%3D%3D&amp;devicetype&#x3D;Windows+10&amp;version&#x3D;62070141&amp;lang&#x3D;zh_CN&amp;pass_ticket&#x3D;nyYDKj4I3yeeGhmjqAetoeoyB7Cl9wuwBsfQ0crSNd0YDaJ2REcnMBJwCYsgcwy2"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2019.newsTitle_12') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </block>
                                          <block v-if="currentYear == 2018">
                                            <!-- 2018-1 -->
                                            <div class="posts-container">
                                              <ul class="posts">
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/5db65a0ea5a9cd524c4f00f2"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2018.newsDate_1') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s?__biz&#x3D;MzI3MjExNjQ4OQ&#x3D;&#x3D;&amp;mid&#x3D;2649886679&amp;idx&#x3D;4&amp;sn&#x3D;616a0f2430685e22dc92275a6cdcd8d1&amp;chksm&#x3D;f331d1b1c44658a71f83eacd5afd0ee0a69775e8f1196abce02c07f674239b7f2081e900abb3&amp;mpshare&#x3D;1&amp;scene&#x3D;1&amp;srcid&#x3D;&amp;sharer_sharetime&#x3D;1570161227236&amp;sharer_shareid&#x3D;3463421222946fc485976b13826e9903&amp;key&#x3D;5a37b8e9f293346393360845e9678a103e98b15d42b4a8d39a7f6226411c261d19c15f846bbfdcb53e8b66962a9575fc2b3da6e18456fb673c18253e6599e1402bb18c012adf73fa39dd730f014fe8dd&amp;ascene&#x3D;1&amp;uin&#x3D;MjQwODg0NTcwMw%3D%3D&amp;devicetype&#x3D;Windows+10&amp;version&#x3D;62070141&amp;lang&#x3D;zh_CN&amp;pass_ticket&#x3D;nyYDKj4I3yeeGhmjqAetoeoyB7Cl9wuwBsfQ0crSNd0YDaJ2REcnMBJwCYsgcwy2"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2018.newsTitle_1') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/5db651a6a5a9cd524c4eff26"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2018.newsDate_2') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s?__biz&#x3D;MzI3MjExNjQ4OQ&#x3D;&#x3D;&amp;mid&#x3D;2649886663&amp;idx&#x3D;3&amp;sn&#x3D;a34d9464822a33a4e5fee65ab1049ec1&amp;chksm&#x3D;f331d1a1c44658b7ae50f282f2b93ec44754362c9ab9a84e8256f5467ac9c4dfd8278cf597f1&amp;mpshare&#x3D;1&amp;scene&#x3D;1&amp;srcid&#x3D;&amp;sharer_sharetime&#x3D;1570161235017&amp;sharer_shareid&#x3D;3463421222946fc485976b13826e9903&amp;key&#x3D;b1719993cc296ec415d7d0b877deef711c8bafa8c04f780b670a1156754fcd5907c575ea374617180b281a86bf13af912d4a5d7fd43dcb8c0585c9cbde15fdf4556a5c67d24d5325f76343cace02e1ea&amp;ascene&#x3D;1&amp;uin&#x3D;MjQwODg0NTcwMw%3D%3D&amp;devicetype&#x3D;Windows+10&amp;version&#x3D;62070141&amp;lang&#x3D;zh_CN&amp;pass_ticket&#x3D;nyYDKj4I3yeeGhmjqAetoeoyB7Cl9wuwBsfQ0crSNd0YDaJ2REcnMBJwCYsgcwy2"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2018.newsTitle_2') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li class="post">
                                                  <div class="img-container">
                                                    <img
                                                      src="https://app.irlink.com.hk/api/attachment/5db64ff0597e0f52520f7cdd"
                                                    />
                                                  </div>
                                                  <div class="content">
                                                    <div class="date">
                                                      {{ $t('pages.active.news2018.newsDate_3') }}
                                                    </div>
                                                    <div class="subject">
                                                      <a
                                                        href="https://mp.weixin.qq.com/s?__biz&#x3D;MzI3MjExNjQ4OQ&#x3D;&#x3D;&amp;mid&#x3D;2649886631&amp;idx&#x3D;2&amp;sn&#x3D;f7ad84089264c5f141f82d15b4e1ec10&amp;chksm&#x3D;f331d1c1c44658d731bd28a325f93f4c7910e81d6ad196fbe285f33df1a78eb0fe5cacd0bdd5&amp;mpshare&#x3D;1&amp;scene&#x3D;1&amp;srcid&#x3D;&amp;sharer_sharetime&#x3D;1570161240432&amp;sharer_shareid&#x3D;3463421222946fc485976b13826e9903&amp;key&#x3D;69f28381a3e17e703bcb8af579c5acf1e799bb96d9c982757e611e3d14c200ae121cabb06cb052894feff30d694472d820405d3c11b2ac2ced4905ced9d62c30320055dae2343f849ff9277aa4fbd4d6&amp;ascene&#x3D;1&amp;uin&#x3D;MjQwODg0NTcwMw%3D%3D&amp;devicetype&#x3D;Windows+10&amp;version&#x3D;62070141&amp;lang&#x3D;zh_CN&amp;pass_ticket&#x3D;nyYDKj4I3yeeGhmjqAetoeoyB7Cl9wuwBsfQ0crSNd0YDaJ2REcnMBJwCYsgcwy2"
                                                        target="_blank"
                                                      >
                                                        {{ $t('pages.active.news2018.newsTitle_3') }}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </block>
                                          <ul class="paginator" v-if="currentYear == 2021">
                                            <li class="first" @click="first">
                                              <a
                                                href="javascript:void(0)"
                                                data-page="1"
                                                style="cursor: initial"
                                              >
                                                <div class="paging-button" style="top: 3px">
                                                  <div
                                                    class="first-last"
                                                    :style="prev == 'tclick' ? sclickLine : unclickLine"
                                                  ></div>
                                                  <div
                                                    class="left-triangle"
                                                    :style="prev == 'tclick' ? lsclick : lunclick"
                                                  ></div>
                                                </div>
                                              </a>
                                            </li>
                                            <li class="previous" @click="previous">
                                              <a href="javascript:void(0)" style="cursor: initial">
                                                <div class="paging-button">
                                                  <div
                                                    class="left-triangle"
                                                    :style="prev == 'tclick' ? lsclick : lunclick"
                                                  ></div>
                                                </div>
                                              </a>
                                            </li>
                                            <li
                                              @click="changePage"
                                              :class="[
                                                {
                                                  current: currentPage == 1 ? true : false,
                                                },
                                                page,
                                              ]"
                                              data-page="1"
                                            >
                                              <a href="javascript:void(0)">1</a>
                                            </li>
                                            <li
                                              @click="changePage"
                                              :class="[
                                                {
                                                  current: currentPage == 2 ? true : false,
                                                },
                                                page,
                                              ]"
                                              data-page="2"
                                            >
                                              <a href="javascript:void(0)">2</a>
                                            </li>
                                            <li
                                              @click="changePage"
                                              :class="[
                                                {
                                                  current: currentPage == 3 ? true : false,
                                                },
                                                page,
                                              ]"
                                              data-page="3"
                                            >
                                              <a href="javascript:void(0)" data-page="3">3</a>
                                            </li>
                                            <li
                                              @click="changePage"
                                              :class="[
                                                {
                                                  current: currentPage == 4 ? true : false,
                                                },
                                                page,
                                              ]"
                                              data-page="4"
                                            >
                                              <a href="javascript:void(0)" data-page="4">4</a>
                                            </li>
                                            <li
                                              @click="changePage"
                                              :class="[
                                                {
                                                  current: currentPage == 5 ? true : false,
                                                },
                                                page,
                                              ]"
                                              data-page="5"
                                            >
                                              <a href="javascript:void(0)" data-page="5">5</a>
                                            </li>
                                            <li class="next" @click="next">
                                              <a
                                                href="javascript:void(0)"
                                                data-page="2"
                                                style="cursor: pointer"
                                              >
                                                <div class="paging-button">
                                                  <div
                                                    class="right-triangle"
                                                    :style="next1 == 'tclick' ? rsclick : runclick"
                                                  ></div>
                                                </div>
                                              </a>
                                            </li>
                                            <li class="last" @click="last">
                                              <a
                                                href="javascript:void(0)"
                                                data-page="5"
                                                style="cursor: pointer"
                                              >
                                                <div class="paging-button">
                                                  <div
                                                    class="right-triangle"
                                                    :style="next1 == 'tclick' ? rsclick : runclick"
                                                  ></div>
                                                  <div
                                                    class="first-last"
                                                    :style="next1 == 'tclick' ? sclickLine : unclickLine"
                                                  ></div>
                                                </div>
                                              </a>
                                            </li>
                                          </ul>
                                          <ul class="paginator" v-if="currentYear == 2020">
                                            <li class="first" @click="first">
                                              <a
                                                href="javascript:void(0)"
                                                data-page="1"
                                                style="cursor: initial"
                                              >
                                                <div class="paging-button" style="top: 3px">
                                                  <div
                                                    class="first-last"
                                                    :style="prev == 'tclick' ? sclickLine : unclickLine"
                                                  ></div>
                                                  <div
                                                    class="left-triangle"
                                                    :style="prev == 'tclick' ? lsclick : lunclick"
                                                  ></div>
                                                </div>
                                              </a>
                                            </li>
                                            <li class="previous" @click="previous">
                                              <a
                                                href="javascript:void(0)"
                                                data-page=""
                                                style="cursor: initial"
                                              >
                                                <div class="paging-button">
                                                  <div
                                                    class="left-triangle"
                                                    :style="prev == 'tclick' ? lsclick : lunclick"
                                                  ></div>
                                                </div>
                                              </a>
                                            </li>
                                            <li
                                              @click="changePage"
                                              :class="[
                                                {
                                                  current: currentPage == 1 ? true : false,
                                                },
                                                page,
                                                {
                                                  none: currentPage > 3 ? true : false,
                                                },
                                              ]"
                                              data-page="1"
                                            >
                                              <a href="javascript:void(0)">1</a>
                                            </li>
                                            <li
                                              @click="changePage"
                                              :class="[
                                                {
                                                  current: currentPage == 2 ? true : false,
                                                },
                                                page,
                                                {
                                                  none: currentPage > 4 ? true : false,
                                                },
                                              ]"
                                              data-page="2"
                                            >
                                              <a href="javascript:void(0)">2</a>
                                            </li>
                                            <li
                                              @click="changePage"
                                              :class="[
                                                {
                                                  current: currentPage == 3 ? true : false,
                                                },
                                                page,
                                                {
                                                  none: currentPage > 5 ? true : false,
                                                },
                                              ]"
                                              data-page="3"
                                            >
                                              <a href="javascript:void(0)" data-page="3">3</a>
                                            </li>
                                            <li
                                              @click="changePage"
                                              :class="[
                                                {
                                                  current: currentPage == 4 ? true : false,
                                                },
                                                page,
                                                {
                                                  none: currentPage > 6 ? true : false,
                                                },
                                              ]"
                                              data-page="4"
                                            >
                                              <a href="javascript:void(0)" data-page="4">4</a>
                                            </li>
                                            <li
                                              @click="changePage"
                                              :class="[
                                                {
                                                  current: currentPage == 5 ? true : false,
                                                },
                                                page,
                                                {
                                                  none: currentPage > 7 ? true : false,
                                                },
                                              ]"
                                              data-page="5"
                                            >
                                              <a href="javascript:void(0)" data-page="5">5</a>
                                            </li>
                                            <li
                                              @click="changePage"
                                              :class="[
                                                {
                                                  current: currentPage == 6 ? true : false,
                                                },
                                                page,
                                                {
                                                  none: currentPage < 4 ? true : false,
                                                },
                                              ]"
                                              data-page="6"
                                            >
                                              <a href="javascript:void(0)" data-page="6">6</a>
                                            </li>
                                            <li
                                              @click="changePage"
                                              :class="[
                                                {
                                                  current: currentPage == 7 ? true : false,
                                                },
                                                page,
                                                {
                                                  none: currentPage < 5 ? true : false,
                                                },
                                              ]"
                                              data-page="7"
                                            >
                                              <a href="javascript:void(0)" data-page="7">7</a>
                                            </li>
                                            <li
                                              @click="changePage"
                                              :class="[
                                                {
                                                  current: currentPage == 8 ? true : false,
                                                },
                                                page,
                                                {
                                                  none: currentPage < 6 ? true : false,
                                                },
                                              ]"
                                              data-page="8"
                                            >
                                              <a href="javascript:void(0)" data-page="8">8</a>
                                            </li>
                                            <li
                                              @click="changePage"
                                              :class="[
                                                {
                                                  current: currentPage == 9 ? true : false,
                                                },
                                                page,
                                                {
                                                  none: currentPage < 7 ? true : false,
                                                },
                                              ]"
                                              data-page="9"
                                            >
                                              <a href="javascript:void(0)" data-page="9">9</a>
                                            </li>
                                            <li
                                              @click="changePage"
                                              :class="[
                                                {
                                                  current: currentPage == 10 ? true : false,
                                                },
                                                page,
                                                {
                                                  none: currentPage < 8 ? true : false,
                                                },
                                              ]"
                                              data-page="10"
                                            >
                                              <a href="javascript:void(0)" data-page="10">10</a>
                                            </li>
                                            <li class="next" @click="next">
                                              <a
                                                href="javascript:void(0)"
                                                data-page="2"
                                                style="cursor: pointer"
                                              >
                                                <div class="paging-button">
                                                  <div
                                                    class="right-triangle"
                                                    :style="next1 == 'tclick' ? rsclick : runclick"
                                                  ></div>
                                                </div>
                                              </a>
                                            </li>
                                            <li class="last" @click="last">
                                              <a
                                                href="javascript:void(0)"
                                                data-page="5"
                                                style="cursor: pointer"
                                              >
                                                <div class="paging-button">
                                                  <div
                                                    class="right-triangle"
                                                    :style="next1 == 'tclick' ? rsclick : runclick"
                                                  ></div>
                                                  <div
                                                    class="first-last"
                                                    :style="next1 == 'tclick' ? sclickLine : unclickLine"
                                                  ></div>
                                                </div>
                                              </a>
                                            </li>
                                          </ul>

                                          <ul class="paginator" v-if="currentYear == 2019">
                                            <li class="first" @click="first">
                                              <a
                                                href="javascript:void(0)"
                                                data-page="1"
                                                style="cursor: initial"
                                              >
                                                <div class="paging-button" style="top: 3px">
                                                  <div
                                                    class="first-last"
                                                    :style="prev == 'tclick' ? sclickLine : unclickLine"
                                                  ></div>
                                                  <div
                                                    class="left-triangle"
                                                    :style="prev == 'tclick' ? lsclick : lunclick"
                                                  ></div>
                                                </div>
                                              </a>
                                            </li>
                                            <li class="previous" @click="previous">
                                              <a
                                                href="javascript:void(0)"
                                                data-page=""
                                                style="cursor: initial"
                                              >
                                                <div class="paging-button">
                                                  <div
                                                    class="left-triangle"
                                                    :style="prev == 'tclick' ? lsclick : lunclick"
                                                  ></div>
                                                </div>
                                              </a>
                                            </li>
                                            <li
                                              @click="changePage"
                                              :class="[
                                                {
                                                  current: currentPage == 1 ? true : false,
                                                },
                                                page,
                                              ]"
                                              data-page="1"
                                            >
                                              <a href="javascript:void(0)">1</a>
                                            </li>
                                            <li
                                              @click="changePage"
                                              :class="[
                                                {
                                                  current: currentPage == 2 ? true : false,
                                                },
                                                page,
                                              ]"
                                              data-page="2"
                                            >
                                              <a href="javascript:void(0)">2</a>
                                            </li>
                                            <li class="next" @click="next">
                                              <a
                                                href="javascript:void(0)"
                                                data-page="2"
                                                style="cursor: pointer"
                                              >
                                                <div class="paging-button">
                                                  <div
                                                    class="right-triangle"
                                                    :style="next1 == 'tclick' ? rsclick : runclick"
                                                  ></div>
                                                </div>
                                              </a>
                                            </li>
                                            <li class="last" @click="last">
                                              <a
                                                href="javascript:void(0)"
                                                data-page="5"
                                                style="cursor: pointer"
                                              >
                                                <div class="paging-button">
                                                  <div
                                                    class="right-triangle"
                                                    :style="next1 == 'tclick' ? rsclick : runclick"
                                                  ></div>
                                                  <div
                                                    class="first-last"
                                                    :style="next1 == 'tclick' ? sclickLine : unclickLine"
                                                  ></div>
                                                </div>
                                              </a>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
        <br />
        <br />
      </div>

      <v-footer></v-footer>
    </div>
  </div>
</template>

<script>
import vFooter from '../components/footer';
import vHeader from '../components/header';
export default {
  name: 'first',
  components: {
    vFooter,
    vHeader,
  },

  data() {
    return {
      dropdownBlock: '',
      currentYear: 2021,
      currentPage: 1,
      nextPage: this.currentPage + 1,
      prev: 'unclick',
      next1: 'tclick',
      lunclick: 'border-color: transparent #888 transparent transparent;',
      lsclick: 'border-color: transparent #70ad47 transparent transparent;',

      runclick: 'border-color: transparent transparent transparent #888',
      rsclick: 'border-color:  transparent transparent transparent #70ad47',
      unclickLine: 'background-color: #888',
      sclickLine: 'background-color: #70ad47',
    };
  },

  methods: {
    dropdownList() {
      if (this.dropdownBlock) {
        this.dropdownBlock = '';
      } else {
        this.dropdownBlock = 'dropdown-block';
      }
    },

    change(e) {
      let _year = e.currentTarget.dataset.year;
      this.currentYear = _year;

      this.currentPage = 1;
    },

    changePage(e) {
      let _page = e.currentTarget.dataset.page;
      this.currentPage = _page;
      if (this.currentPage == 1) {
        this.prev = 'unclick';
        this.next1 = 'tclick';
      } else {
        if (this.currentYear == 2021) {
          if (this.currentPage == 5) {
            this.prev = 'tclick';
            this.next1 = 'unclick';
          } else {
            this.prev = 'tclick';
            this.next1 = 'tclick';
          }
        } else if (this.currentYear == 2020) {
          if (this.currentPage == 10) {
            this.prev = 'tclick';
            this.next1 = 'unclick';
          } else {
            this.prev = 'tclick';
            this.next1 = 'tclick';
          }
        } else {
          if (this.currentPage == 2) {
            this.prev = 'tclick';
            this.next1 = 'unclick';
          } else {
            this.prev = 'tclick';
            this.next1 = 'tclick';
          }
        }
      }
    },

    first() {
      if (this.prev == 'unclick') {
        return;
      }
      if (this.currentYear != 2018) {
        this.currentPage = 1;
        this.prev = 'unclick';
        this.next1 = 'tclick';
      }
    },

    last() {
      if (this.next1 == 'unclick') {
        return;
      }

      if (this.currentYear != 2018) {
        if (this.currentYear == 2020) {
          this.currentPage = 10;
          this.next1 = 'unclick';
          this.prev = 'tclick';
          return;
        } else if (this.currentYear == 2021) {
          this.currentPage = 5;
          this.next1 = 'unclick';
          this.prev = 'tclick';
          return;
        } else {
          this.currentPage = 2;
          this.next1 = 'unclick';
          this.prev = 'tclick';
          return;
        }
      }
    },

    previous() {
      if (this.prev == 'unclick') {
        return;
      }
      if (this.currentYear == 2021 || this.currentYear == 2019) {
        this.currentPage--;
        if (this.currentYear == 2021) {
          if (this.currentPage == 1) {
            this.prev = 'unclick';
            this.next1 = 'tclick';
            return;
          }
        } else {
          if (this.currentPage == 1) {
            this.prev = 'unclick';
            this.next1 = 'tclick';
            return;
          }
        }
      }
    },

    next() {
      if (this.next1 == 'unclick') {
        return;
      }
      if (this.currentYear == 2021 || this.currentYear == 2019) {
        this.currentPage++;

        if (this.currentYear == 2021) {
          if (this.currentPage == 5) {
            this.next1 = 'unclick';
            this.prev = 'tclick';
            return;
          } else {
            this.next1 = 'tclick';
            this.prev = 'tclick';
          }
        } else {
          if (this.currentPage == 2) {
            this.next1 = 'unclick';
            this.prev = 'tclick';
            return;
          } else {
            this.next1 = 'tclick';
            this.prev = 'tclick';
          }
        }
      }
    },
  },
};
</script>

<style scoped>
.irlink-blog-posts2 {
  position: relative;
  bottom: 30px;
}
.irlink-blog-posts2 a,
.irlink-blog-posts a:hover {
  box-shadow: none;
}
.irlink-blog-posts2 .year-select {
  margin-bottom: 50px;
  text-align: right;
}
.irlink-blog-posts2 .left-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.irlink-blog-posts2 .select-box {
  display: inline-block;
  position: relative;
  margin: 0 0 0 8px;
  user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  z-index: 1;
}
.irlink-blog-posts2 .select-box .label {
  position: relative;
  display: inline-block;
  padding: 4px 50px 4px 30px;
  border: 2px solid #888;
  cursor: pointer;
  font-size: 20px;
}
.irlink-blog-posts2 .select-box .dropdown {
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  background-color: #fff;
  border: 1px solid #888;
}
.irlink-blog-posts2 .select-box .dropdown.dropdown-block {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  background-color: #fff;
  border: 1px solid #888;
}
.irlink-blog-posts2 .select-box .dropdown li {
  text-align: center;
  cursor: pointer;
  padding: 0;
  margin: 0;
}
.irlink-blog-posts2 .select-box .dropdown li a {
  display: block;
  width: 100%;
  padding: 4px 12px;
  font-size: 16px;
  color: #70ad47;
}
.irlink-blog-posts2 .select-box .dropdown li:hover {
  background-color: #70ad47;
}
.irlink-blog-posts2 .select-box .dropdown li:hover a {
  color: #fff;
}
.irlink-blog-posts2 .chevron-arrow-down {
  display: inline-block;
  border-right: 3px solid #888;
  border-bottom: 3px solid #888;
  width: 12px;
  height: 12px;
  transform: rotate(45deg);
  position: absolute;
  right: 16px;
  top: 50%;
  margin-top: -8px;
}
.irlink-blog-posts2 ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.irlink-blog-posts2 ul li {
  margin-bottom: 12px;
}
.irlink-blog-posts2 .posts-container {
  display: flex;
  justify-content: center;
}
.irlink-blog-posts2 .posts {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}
.irlink-blog-posts2 .post {
  width: 32%;
  border: 1px #70ad47 solid;
  padding: 15px;
  margin: 5px;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
}
.irlink-blog-posts2 .post img {
  width: 100%;
  height: 230px;
}
.irlink-blog-posts2 .post .content {
  padding-top: 5px;
}
.irlink-blog-posts2 .post .date {
  font-size: larger;
  color: #000;
}
.irlink-blog-posts2 .post .subject {
  color: #000;
}
.irlink-blog-posts2 .post .subject a {
  color: #222;
  font-size: larger;
}
.irlink-blog-posts2 .post a:hover {
  color: #70ad47;
  text-decoration: none;
}
.irlink-blog-posts2 .paginator {
  text-align: center;
  padding-top: 20px;
}
.irlink-blog-posts2 .paginator li {
  display: inline-block;
  margin-right: 5px;
}
.irlink-blog-posts2 .paginator li a {
  display: inline-block;
  padding: 2px 4px;
  color: #222;
  font-size: 18px;
}
.irlink-blog-posts2 .paginator li.current a {
  display: inline-block;
  padding: 2px 4px;
  font-weight: bold;
}
.irlink-blog-posts2 .current-year {
  color: #70ad47;
  font-size: 30px;
}
.irlink-blog-posts2 .paging-button {
  position: relative;
  top: 1px;
  display: flex;
}
.irlink-blog-posts2 .first-last {
  width: 3px;
  height: 18px;
  background-color: #70ad47;
}
.irlink-blog-posts2 .left-triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 13.84px 8px 0;
  border-color: transparent #70ad47 transparent transparent;
}
.irlink-blog-posts2 .right-triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 0 8px 13.84px;
  border-color: transparent transparent transparent #70ad47;
}
@media only screen and (max-width: 768px) {
  .irlink-blog-posts2 .posts {
    flex-direction: column;
    align-items: center;
  }
  .irlink-blog-posts2 .post {
    flex-direction: column;
    width: 70%;
    margin-bottom: 30px;
  }
  .irlink-blog-posts2 .post img {
    width: 100%;
    height: auto;
    min-height: 120px;
  }
}
@media only screen and (max-width: 640px) {
  .irlink-blog-posts2 .post {
    padding-bottom: 5px;
  }
  .irlink-blog-posts2 .post img {
    width: 100%;
    height: auto;
    min-height: 120px;
  }
  .irlink-blog-posts2 .post .date {
    width: 100%;
  }
  .irlink-blog-posts2 .post .subject {
    width: 100%;
  }
}

.none {
  display: none !important;
}
</style>
