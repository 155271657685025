<template>
  <div class="fl-page-footer-wrap">
    <div class="fl-page-footer">
      <div class="fl-page-footer-container container">
        <div class="fl-page-footer-row row">
          <div class="col-md-12 text-center clearfix">
            <div class="fl-page-footer-text fl-page-footer-text-1">
              © 2019 中国三江精细化工有限公司
              <a href="https://irlink.com.hk">Powered by IRLink</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },

  methods: {},
};
</script>

<style scoped></style>
