<template>
  <div
    id="index_num"
    class="
      home
      page-template-default page page-id-5
      fl-builder fl-framework-base fl-preset-default fl-full-width
    "
    itemscope="itemscope"
  >
    <div class="fl-page">
      <v-header :fixed="fixed"></v-header>
      <div class="fl-page-content" itemprop="mainContentOfPage">
        <div class="fl-content-full container">
          <div class="row">
            <div class="fl-content col-md-12">
              <article
                class="fl-post post-5 page type-page status-publish hentry"
                id="fl-post-5"
                itemscope="itemscope"
              >
                <div class="fl-post-content clearfix" itemprop="text">
                  <div
                    class="
                      fl-builder-content
                      fl-builder-content-5
                      fl-builder-content-primary
                      fl-builder-global-templates-locked
                    "
                    data-post-id="5"
                  >
                    <div
                      class="
                        fl-row
                        fl-row-full-width
                        fl-row-bg-photo
                        fl-node-5df1dabc788ce
                        fl-visible-desktop-medium
                      "
                      data-node="5df1dabc788ce"
                    >
                      <div class="fl-row-content-wrap fl-row-content-wrap-index">
                        <div class="fl-row-content fl-row-full-width fl-node-content">
                          <div class="fl-col-group fl-node-5df1dabc7a895" data-node="5df1dabc7a895">
                            <div class="fl-col fl-node-5df1dabc7a96c" data-node="5df1dabc7a96c">
                              <div class="fl-col-content fl-node-content">
                                <div
                                  class="fl-module fl-module-rich-text fl-node-5df1db3c6ee98"
                                  data-node="5df1db3c6ee98"
                                >
                                  <div class="fl-module-content fl-node-content">
                                    <div class="fl-rich-text">
                                      <p>&nbsp;</p>
                                      <p>&nbsp;</p>
                                      <p>&nbsp;</p>
                                      <p>&nbsp;</p>
                                      <p>&nbsp;</p>
                                      <p>&nbsp;</p>
                                      <p>&nbsp;</p>
                                      <p>&nbsp;</p>
                                      <p>&nbsp;</p>
                                      <p>&nbsp;</p>
                                      <p>&nbsp;</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="fl-row fl-row-fixed-width fl-row-bg-none fl-node-5de4ba9e4590d"
                      data-node="5de4ba9e4590d"
                    >
                      <div class="fl-row-content-wrap">
                        <div class="fl-row-content fl-row-fixed-width fl-node-content">
                          <div class="fl-col-group fl-node-5de4ba9e46b02" data-node="5de4ba9e46b02">
                            <div
                              class="fl-col fl-node-5de4ba9e46bdd fl-col-small"
                              data-node="5de4ba9e46bdd"
                            >
                              <div class="fl-col-content fl-node-content">
                                <div
                                  class="fl-module fl-module-rich-text fl-node-5de4bb007835a"
                                  data-node="5de4bb007835a"
                                >
                                  <div class="fl-module-content fl-node-content">
                                    <div class="fl-rich-text">
                                      <h1>
                                        {{ $t('pages.first.companyAnnouncement.title') }}
                                      </h1>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  class="fl-module fl-module-rich-text fl-node-5de4bb788d38b"
                                  data-node="5de4bb788d38b"
                                >
                                  <div class="fl-module-content fl-node-content">
                                    <div class="fl-rich-text">
                                      <div
                                        class="
                                          fl-module
                                          fl-module-rich-text
                                          fl-node-5da3683631539
                                          fl-animation
                                          fl-slide-left
                                          fl-animated
                                        "
                                        data-node="5da3683631539"
                                        data-animation-delay="0.0"
                                      >
                                        <div class="fl-module-content fl-node-content">
                                          <div class="fl-rich-text">
                                            <div>
                                              <div class="irlink-hkex-news-root">
                                                <ul class="news">
                                                  <li
                                                    v-for="(item, index) in documents"
                                                    :key="index"
                                                    class="irlink-hkex-news-item"
                                                  >
                                                    <div class="irlink-hkex-news-content">
                                                      <div class="arrow-right"></div>
                                                      <a
                                                        :href="item.url"
                                                        target="_blank"
                                                        class="irlink-hkex-news-link"
                                                      >
                                                        {{ item.title }}
                                                      </a>
                                                    </div>
                                                    <div class="irlink-hkex-news-date">
                                                      {{ item.date }}
                                                    </div>
                                                  </li>
                                                </ul>
                                                <form style="display: none">
                                                  <input
                                                    type="hidden"
                                                    name="context"
                                                    value='{"lang":"sc","limit":5,"newsType":"","paging":false,"filters":{"mode":"classic","stockCode":"2198 HK","newsType":""},"dateFormat":"YYYY年MM月DD日","template":"sanjiang-news-landing","rootClass":"","byAjax":true}'
                                                  /><input
                                                    type="hidden"
                                                    name="site"
                                                    value="https://irlinkdemo.com/sanjiang"
                                                  />
                                                </form>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              class="fl-col fl-node-5de4ba9e46c22 fl-col-small"
                              data-node="5de4ba9e46c22"
                            >
                              <div class="fl-col-content fl-node-content">
                                <div
                                  class="fl-module fl-module-rich-text fl-node-5de4baeadf1cb"
                                  data-node="5de4baeadf1cb"
                                >
                                  <div class="fl-module-content fl-node-content">
                                    <div class="fl-rich-text">
                                      <h1>
                                        {{ $t('pages.first.contactInfo.title') }}
                                      </h1>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  class="fl-module fl-module-rich-text fl-node-5de4bad81f014"
                                  data-node="5de4bad81f014"
                                >
                                  <div class="fl-module-content fl-node-content">
                                    <div class="fl-rich-text">
                                      <div
                                        class="
                                          fl-module
                                          fl-module-rich-text
                                          fl-node-5da3683631539
                                          fl-animation
                                          fl-slide-left
                                          fl-animated
                                        "
                                        data-node="5da3683631539"
                                        data-animation-delay="0.0"
                                      >
                                        <div class="fl-module-content fl-node-content">
                                          <div class="fl-rich-text">
                                            <p>
                                              <span style="font-size: 20px">
                                                {{ $t('pages.first.contactInfo.hkAddress') }}
                                              </span>
                                            </p>
                                            <p>
                                              <span style="font-size: 20px">{{
                                                $t('pages.first.contactInfo.contactPerson_2')
                                              }}</span
                                              ><br />
                                              <span style="font-size: 20px"
                                                ><a href="mailto:henryyip@jxsjchem.com">{{
                                                  $t('pages.first.contactInfo.contactEmail_2')
                                                }}</a></span
                                              ><br />
                                              <span style="font-size: 20px">{{
                                                $t('pages.first.contactInfo.contactNo_2')
                                              }}</span>
                                            </p>
                                            <p>
                                              <span style="font-size: 20px">
                                                {{ $t('pages.first.contactInfo.cnAddress') }}
                                              </span>
                                            </p>
                                            <p>
                                              <span style="font-size: 20px">{{
                                                $t('pages.first.contactInfo.contactPerson_1')
                                              }}</span
                                              ><br />
                                              <span style="font-size: 20px"
                                                ><a href="mailto:chenx@jxsjchem.com">{{
                                                  $t('pages.first.contactInfo.contactEmail_1')
                                                }}</a></span
                                              ><br />
                                              <span style="font-size: 20px">{{
                                                $t('pages.first.contactInfo.contactNo_1')
                                              }}</span>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
        <br />
        <br />
      </div>
      <v-footer></v-footer>
    </div>
  </div>
</template>

<script>
import vFooter from '../components/footer';
import vHeader from '../components/header';
import api from '../api';
import i18n from '@/locale';

export default {
  name: 'first',
  components: {
    vFooter,
    vHeader,
  },
  data() {
    return {
      fixed: false,
      documents: [],
    };
  },
  watch: {
    '$i18n.locale': function () {
      this.getDocuments();
    },
  },
  methods: {
    async getDocuments() {
      const locale = i18n.locale != 'zh_CN' ? 'en' : 'sc';
      const year = new Date().getFullYear();
      this.documents = (await api.document.get(locale, year, 1, 6)).data || [];
    },
  },
  mounted() {
    this.getDocuments();
  },
};
</script>

<style scoped>
.irlink-hkex-news-root {
  font-size: 17pt;
  position: relative;
  bottom: 30px;
}
.irlink-hkex-news-root a {
  color: #222;
}
.irlink-hkex-news-root .irlink-hkex-news-item:nth-child(odd) {
  background-color: transparent;
}
.irlink-hkex-news-root ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
.irlink-hkex-news-root .irlink-hkex-news-item {
  padding: 8px 8px;
  display: block;
  border-bottom: 2px #d4faf7 solid;
}
.irlink-hkex-news-root .irlink-hkex-news-item:hover {
  background-color: #70ad47;
}
.irlink-hkex-news-root a,
.irlink-hkex-news-root a:hover {
  text-decoration: none;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}
.irlink-hkex-news-root .arrow-left {
  border-top: 3px #222 solid;
  border-left: 3px #222 solid;
  background-color: transparent;
  height: 10px;
  width: 10px;
  transform: rotate(-45deg);
  display: inline-block;
  margin-left: 2px;
}
.irlink-hkex-news-root .arrow-right {
  border-top: 3px #222 solid;
  border-right: 3px #222 solid;
  background-color: transparent;
  height: 10px;
  width: 10px;
  transform: rotate(45deg);
  display: inline-block;
  margin-right: 2px;
}
.irlink-hkex-news-root .irlink-hkex-news-item .irlink-hkex-news-content {
  color: #000;
  font-size: smaller;
  display: flex;
  align-items: center;
}
/*.irlink-hkex-news-root .irlink-hkex-news-item .irlink-hkex-news-content a:hover {*/
/*  color: #2386EE;*/
/*}*/
.irlink-hkex-news-root .irlink-hkex-news-date {
  font-size: small;
  padding-top: 10px;
  padding-bottom: 15px;
  color: #000;
  text-align: left;
}
.irlink-hkex-news-root .irlink-hkex-news-link {
  margin-left: 10px;
}
.irlink-hkex-news-root .year-select {
  margin-bottom: 12px;
  text-align: right;
}
.irlink-hkex-news-root .left-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.irlink-hkex-news-root .select-box {
  display: inline-block;
  position: relative;
  margin: 0 0 0 8px;
  user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  z-index: 1;
}
.irlink-hkex-news-root .select-box .label {
  position: relative;
  display: inline-block;
  padding: 4px 50px 4px 30px;
  border: 2px solid #888;
  cursor: pointer;
  font-size: 20px;
}
.irlink-hkex-news-root .select-box .dropdown {
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  background-color: #fff;
  border: 1px solid #888;
}
.irlink-hkex-news-root .select-box .dropdown li {
  text-align: center;
  cursor: pointer;
}
.irlink-hkex-news-root .select-box .dropdown li a {
  display: block;
  padding: 4px 12px;
  font-size: 16px;
}
.irlink-hkex-news-root .select-box .dropdown li:hover {
  background-color: #70ad47;
}
.irlink-hkex-news-root .chevron-arrow-down {
  display: inline-block;
  border-right: 3px solid #888;
  border-bottom: 3px solid #888;
  width: 12px;
  height: 12px;
  transform: rotate(45deg);
  position: absolute;
  right: 16px;
  top: 50%;
  margin-top: -8px;
}
.irlink-hkex-news-root .paginator {
  text-align: center;
  padding-top: 20px;
}
.irlink-hkex-news-root .paginator li {
  display: inline-block;
  margin-right: 5px;
}
.irlink-hkex-news-root .paginator li a {
  display: inline-block;
  padding: 2px 4px;
  color: #222;
  font-size: 18px;
}
.irlink-hkex-news-root .paginator li.current a {
  display: inline-block;
  padding: 2px 4px;
  font-weight: bold;
}
.irlink-hkex-news-root .current-year {
  color: #70ad47;
  font-size: 30px;
}
.irlink-hkex-news-root .paging-button {
  position: relative;
  top: 1px;
  display: flex;
}
.irlink-hkex-news-root .first-last {
  width: 3px;
  height: 18px;
  background-color: #70ad47;
}
.irlink-hkex-news-root .left-triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 13.84px 8px 0;
  border-color: transparent #70ad47 transparent transparent;
}
.irlink-hkex-news-root .right-triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 0 8px 13.84px;
  border-color: transparent transparent transparent #70ad47;
}

.sub-menu li {
  text-align: left;
}
</style>
